import c from 'classnames';
import propsFromUrl from 'core/enhancers/propsFromUrl';
import { buttonShadow } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles, ITheme } from 'core/style/interfaces';
import { isMobile } from 'core/utils';
import React from 'react';
import { compose } from 'recompose';
import { ISlideState } from '../courses/CourseList';
import SliderFilter from './SliderFilter';

const styles = (theme: ITheme) =>
    createStyles({
        container: {
            display: 'flex',
            flexDirection: 'column',
            background: theme.colors.contrastBg,
            height: 195,
            width: '100%',
            marginBottom: 1.5 + 'rem',
            justifyContent: 'space-around',
            marginLeft: 15,
            marginRight: 15,

            [theme.breakpoints.down('xs')]: {
                width: '100vw',
                marginLeft: 'calc((-100vw + 100%)/2)',
                marginRight: 'calc((-100vw + 100%)/2)',
            },
        },
        description: {
            fontSize: 1.7 + 'rem',
            fontWeight: 600,
            color: '#fefefe',
            flexGrow: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        },
        buttonContainer: {
            display: 'flex',
            justifyContent: 'space-around',
            marginBottom: 1.8 + 'rem',
        },
        button: {
            ...buttonShadow,
            fontSize: 1 + 'rem',
            padding: 0.6 + 'rem',
            fontWeight: 700,
            background: '#ffffff',
            color: '#333333',
            cursor: 'pointer',
            width: '27%',
            textAlign: 'center',
            border: 'none',

            '&:hover': {
                background: '#cccccc',
            },
        },
        buttonDisabled: {
            background: '#999999',
            color: '#666666',

            '&:hover': {
                background: '#999999',
            },
        },
    });

export type IFilterButton = {
    title: string;
    slideState: ISlideState;
};

const DEFAULT_BUTTON = 0;

const buttons: IFilterButton[] = [
    { title: 'Курсы', slideState: 'COURSES' },
    { title: 'Видеокурсы', slideState: 'VIDEO_COURSES' },
    { title: 'Учебники', slideState: 'TEXTBOOKS' },
];

class MediumFilter extends React.Component<IInnerProps & IOutterProps> {
    handleChangeFilter = (buttonIndex: number) => () => {
        const nextSlide = buttons[buttonIndex];
        this.props.onSlideChanged(nextSlide.slideState);
    };

    render() {
        const { classes, slideState } = this.props;
        const currentSlide = slideState ? buttons.findIndex(s => s.slideState === slideState) : DEFAULT_BUTTON;

        return (
            <div className={classes.container}>
                <div className={classes.description}>Еще у нас есть ...</div>
                {isMobile() ? (
                    <SliderFilter
                        onSlideChanged={this.props.onSlideChanged}
                        showDescription={false}
                        customContainerStyle={{ height: 80, marginBottom: '2rem' }}
                    />
                ) : (
                    <div className={classes.buttonContainer}>
                        {buttons.map((b, i) => (
                            <button
                                key={b.title}
                                onClick={this.handleChangeFilter(i)}
                                disabled={currentSlide === i}
                                className={c(classes.button, {
                                    [classes.buttonDisabled]: currentSlide === i,
                                })}
                            >
                                {b.title}
                            </button>
                        ))}
                    </div>
                )}
            </div>
        );
    }
}

export type IInnerProps = {
    slideState?: ISlideState;
} & WithStyles<typeof styles>;

export type IOutterProps = {
    onSlideChanged: (newSlideState: ISlideState) => void;
};

export default compose<IInnerProps, IOutterProps>(
    propsFromUrl({ query: ['slideState'] }),
    injectSheet(styles),
)(MediumFilter);
