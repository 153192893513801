import { link } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { Link } from 'react-router-dom';

type IBreadcrumbsProps = {
    items: { href?: string; title: string }[];
    contrast: boolean;
};

const Breadcrumbs: React.StatelessComponent<IBreadcrumbsProps & WithStyles<typeof styles>> = ({
    items,
    classes,
    contrast,
}) => (
    <ul className={classes.breadcrumbs}>
        {items.map(({ href, title }, i) => (
            <li
                className={classes.breadcrumbs__item}
                style={{ color: contrast ? 'white' : '#212121' }}
                key={i}
                title={title}
            >
                {href ? (
                    <Link
                        to={href}
                        className={classes.breadcrumbs__itemLink}
                        style={{
                            color: contrast ? 'rgb(204, 204, 204)' : '#597DA3',
                        }}
                    >
                        {title}
                    </Link>
                ) : (
                    title
                )}
            </li>
        ))}
    </ul>
);

const styles = (theme: ITheme) =>
    createStyles({
        breadcrumbs: {
            padding: '1rem 0',
            margin: 0,
            listStyle: 'none',
            display: 'flex',
            flexWrap: 'wrap',
            boxSizing: 'content-box',
        },
        breadcrumbs__item: {
            padding: 0,
            margin: 0,
            whiteSpace: 'nowrap' /* Запрещаем перенос строк */,
            overflow: 'hidden' /* Обрезаем все, что не помещается в область */,
            textOverflow: 'ellipsis',
            fontSize: '0.6875rem',
            cursor: 'default',
            textTransform: 'uppercase',

            '&:last-child': {
                flex: 'initial',
            },

            '&:not(:last-child):after': {
                position: 'relative',
                top: '1px',
                margin: '0 0.75rem',
                opacity: 1,
                content: '"/"',
                color: '#cacaca',
            },
        },
        breadcrumbs__itemLink: {
            ...link('white'),

            '&:hover, &:focus': {
                outline: 'none',
                textDecoration: 'underline',
                color: theme.colors.accentMenu,
            },
        },
    });

export default injectSheet(styles, 'Breadcrumbs')(Breadcrumbs);
