import React from 'react';
import c from 'classnames';
import { WithStyles, ITheme } from 'core/style/interfaces';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { buttonReset } from 'core/style';

export type ISortDirection = 'asc' | 'desc' | 'none';

type ISortArrowsProps<T extends string> = {
    only?: ISortDirection;
    orderFunc: (orderName: T, newDirection: ISortDirection | undefined) => void;
    label?: string;
    ascLabel?: string;
    descLabel?: string;
    orderName: T;
    currentOrderName?: T;
    direction: ISortDirection;
    className?: string;
} & WithStyles<typeof styles>;

// const circleDirection = (direction?: ISortDirection): ISortDirection | undefined => {
//     if (direction === 'asc') return 'desc';
//     if (direction === 'desc') return 'none';
//     return 'asc';
// };

const toggleDirection = (direction?: ISortDirection): ISortDirection => {
    if (direction === 'asc') return 'desc';
    if (direction === 'desc') return 'asc';
    return 'asc';
};

const SortArrows: React.FC<ISortArrowsProps<string>> = ({
    classes,
    label,
    only,
    orderFunc,
    orderName,
    currentOrderName,
    direction,
    className,
    ascLabel,
    descLabel,
}) => {
    const isCurrent = currentOrderName === orderName;
    const isAsc = direction === 'asc' && isCurrent;
    const isDesc = direction === 'desc' && isCurrent;

    return (
        <button
            className={c(classes.sort, className)}
            onClick={() => {
                const next = only ? only : toggleDirection(direction);
                return orderFunc(next === 'none' || !next ? undefined : orderName, next);
            }}
        >
            {label && (
                <span
                    className={c(classes.sortLabel, classes.sortLabelBase, { [classes.sortLabelEnabled]: isCurrent })}
                >
                    {label}
                </span>
            )}
            {descLabel && (
                <span
                    className={c(classes.sortLabelDesc, classes.sortLabelBase, { [classes.sortLabelEnabled]: isDesc })}
                >
                    {descLabel}
                </span>
            )}
            {(() => {
                if (only) {
                    if (only === 'desc') {
                        return <span className={c(classes.arrow, { [classes.enabled]: isDesc })}>↓</span>;
                    }
                    if (only === 'asc') {
                        return <span className={c(classes.arrow, { [classes.enabled]: isAsc })}>↑</span>;
                    }
                } else {
                    return (
                        <>
                            <span className={c(classes.arrow, { [classes.enabled]: isDesc })}>↓</span>
                            <span className={c(classes.arrow, { [classes.enabled]: isAsc })}>↑</span>
                        </>
                    );
                }
            })()}
            {ascLabel && (
                <span className={c(classes.sortLabelAsc, classes.sortLabelBase, { [classes.sortLabelEnabled]: isAsc })}>
                    {ascLabel}
                </span>
            )}
        </button>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        sort: {
            ...buttonReset,
            fontSize: 14,
            display: 'flex',
            cursor: 'pointer',
            color: theme.colors.textLight,

            [theme.breakpoints.up('lg')]: {
                fontSize: 1 + 'rem',
            },
        },
        arrow: {
            color: '#aaa',
        },
        enabled: {
            color: '#000',
            fontWeight: 'bold',
        },
        sortLabelAsc: {
            marginLeft: 4,
        },
        sortLabelDesc: {
            marginRight: 4,
        },
        sortLabel: {
            marginRight: 4,
        },
        sortLabelBase: {
            fontSize: '0.9rem',
            marginTop: 2,

            [theme.breakpoints.up('lg')]: {
                fontSize: 1 + 'rem',
            },
        },
        sortLabelEnabled: {
            color: theme.colors.textBase,
        },
    });

export default injectSheet(styles, 'SortArrows')(SortArrows) as <T extends string>(
    props: Omit<ISortArrowsProps<T>, keyof WithStyles<any>>,
) => React.ReactElement;
