import c from 'classnames';
import { showSnackbar } from 'core/components/snackbar';
import { IProductFormTypeFull, ProductFormTypeFullOrder } from 'core/const';
import { icon } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { isMobile } from 'core/utils';
import { flow, map, orderBy } from 'core/utils/fp';
import { isProductFormSubscriptionAccess } from 'core/utils/product-forms/productForms';
import React from 'react';
import { ProductForm, ProductFormPublicTypeEnum } from 'site/graphql';
import certificateIcon from '../../svg/certificate.svg';
import eventRecordIcon from '../../svg/event-record.svg';
import materialIcon from '../../svg/material.svg';
import offerIcon from '../../svg/pack.svg';
import seminarIcon from '../../svg/seminar.svg';
import videoCourseIcon from '../../svg/video-course.svg';
import webinarIcon from '../../svg/webinar.svg';

const orderIcons = flow(
    map((form: ProductFormPublicTypeEnum) => ({ form, order: ProductFormTypeFullOrder[form] })),
    orderBy(o => o.order, 'asc'),
    map(o => o.form),
);

function createIcon(
    type: IProductFormTypeFull,
    classes: IProductFormsIconsProps['classes'],
    showSubscriptionStar: boolean,
) {
    let Icon: React.StatelessComponent<IIconProps>;
    let text: string;

    switch (type) {
        case 'WEBINAR':
            Icon = webinarIcon;
            text = 'Вебинар';
            break;
        case 'SEMINAR':
            Icon = seminarIcon;
            text = 'Семинар';
            break;
        case 'EVENT_RECORD':
            Icon = eventRecordIcon;
            text = 'Видеозапись';
            break;
        case 'VIDEO_COURSE':
            Icon = videoCourseIcon;
            text = 'Видеокурс';
            break;
        case 'MATERIAL':
            Icon = materialIcon;
            text = 'Материалы';
            break;
        case 'CERTIFICATE':
            Icon = certificateIcon;
            text = 'Сертификат';
            break;
        case 'OFFER':
            Icon = offerIcon;
            text = 'Программа обучения';
            break;
        default:
            return null;
    }

    return (
        <div
            className={classes.item}
            key={type}
            onClick={() =>
                showSubscriptionStar &&
                isMobile() &&
                showSnackbar({ snackbarMessage: 'Доступ при покупке любого курса' })
            }
        >
            <Icon className={classes.icon} />
            <p
                className={c(classes.text, { [classes.subscriptionStar]: showSubscriptionStar })}
                title={showSubscriptionStar ? 'Доступ при покупке любого курса' : null}
            >
                {text}
            </p>
        </div>
    );
}

type IProductFormsIconsProps = {
    productForms: Pick<ProductForm, 'id' | 'type'>[];
} & WithStyles<typeof styles>;

const ProductFormsIcons: React.StatelessComponent<IProductFormsIconsProps> = ({ classes, productForms }) => {
    const types = productForms.map(pf => pf.type);
    const orderedTypes = orderIcons(Array.from(new Set(types)));
    const icons = orderedTypes.map(type =>
        createIcon(type, classes, productForms.filter(pf => pf.type === type).some(isProductFormSubscriptionAccess)),
    );

    return productForms && productForms.length > 0 ? <div className={classes.wrapper}>{icons}</div> : null;
};

const styles = (theme: ITheme) =>
    createStyles({
        wrapper: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            width: '100%',
        },
        item: {
            flex: '1 0 0',
            display: 'flex',
            flexWrap: 'nowrap',
            alignItems: 'center',
            marginBottom: 10,
        },
        icon: {
            flex: 'none',
            ...icon,
            marginRight: 0.5 + 'rem',
            fill: theme.colors.accentLight,
        },
        text: {
            fontSize: 14,
            margin: '0 10px 0 0',
        },
        subscriptionStar: {
            whiteSpace: 'nowrap',

            '&:after': {
                content: '" *"',
            },
        },
    });

export default injectSheet(styles, 'ProductFormsIcons')(ProductFormsIcons);
