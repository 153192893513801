import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { CSSProperties, ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import ArrowLeftIcon from '../../svg/arrow_left.svg';

const dropdownButton = (theme: ITheme): CSSProperties => ({
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover, &:focus': {
        color: theme.colors.accent,

        '& $dropdownButton__icon': {
            fill: theme.colors.accent,
        },
    },
});

export const styles = (theme: ITheme) =>
    createStyles({
        dropdownButtonExtra: {},
        dropdownButton: {
            ...dropdownButton(theme),
        },
        dropdownButton__icon: {
            width: 8,
            height: 8,
            fill: '#3f4647',
            fillOpacity: 0.5,
            transform: 'rotate(-90deg)',

            [theme.breakpoints.up('lg')]: {
                width: 10,
                height: 10,
            },
        },
    });

type IStyle = { hollow: boolean };

export type IDropDownButtonProps = {
    onClick: (obj) => void;
    title?: string;
    name?: string;
    style?: IStyle;
};

class DropDownButton extends React.PureComponent<IDropDownButtonProps & WithStyles<typeof styles>> {
    render() {
        let link = null;

        const { onClick, title, name, classes } = this.props;

        const linkClick = e => {
            e.preventDefault();
            const bodyRect = document.body.getBoundingClientRect();
            const linkRect = link.parentElement.getBoundingClientRect();
            onClick({
                domElement: link,
                offset: {
                    top: linkRect.top - bodyRect.top,
                    bottom: linkRect.bottom - bodyRect.top,
                    left: linkRect.left,
                    right: linkRect.right,
                },
                width: linkRect.width,
                height: linkRect.height,
            });
        };

        return (
            <a
                ref={a => (link = a)}
                className={c(classes.dropdownButton, classes.dropdownButtonExtra)}
                title={title}
                onClick={linkClick}
            >
                {name} <ArrowLeftIcon className={classes.dropdownButton__icon} />
            </a>
        );
    }
}

export default injectSheet(styles)(DropDownButton);
