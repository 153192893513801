import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';

type IInvestmentDisclaimerProps = { className?: string } & WithStyles<typeof styles>;

const InvestmentDisclaimer: React.FC<IInvestmentDisclaimerProps> = ({ classes, className }) => (
    <>
        <p className={c(className, classes.disclaimer)}>
            Не является персональным инвестиционным советом или рекомендацией
        </p>
    </>
);

const styles = (theme: ITheme) =>
    createStyles({
        disclaimer: {
            textAlign: 'center',
            fontSize: 14,
            lineHeight: 14 + 'px',
            margin: '1rem auto 0',
            maxWidth: 600,
            padding: 1 + 'rem',
            backgroundColor: '#e8f4fd',
            color: theme.colors.black,
        },
    });

export default injectSheet(styles)(InvestmentDisclaimer);
