import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { ifBrowser } from 'core/utils';
import React from 'react';
import {
    OKIcon,
    OKShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    VKIcon,
    VKShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from 'react-share';
// import { FacebookShareButton, FacebookIcon} from 'react-share';
// import FacebookIcon from '../../svg/facebook-rounded-rect.svg';

const SocialShare: React.FC<WithStyles<typeof styles>> = ({ classes }) => {
    const url = ifBrowser() ? window.location.href : '';

    return (
        <div className={classes.socialContainer}>
            {/* <FacebookShareButton url={url} className={classes.socialButton} translate={null} data-tooltip="Поделиться">
                <FacebookIcon className={classes.socialIcon} />
            </FacebookShareButton> */}
            <TelegramShareButton url={url} className={classes.socialButton} data-tooltip="Поделиться в Telegram">
                <TelegramIcon className={classes.socialIcon} round />
            </TelegramShareButton>
            <VKShareButton url={url} translate="yes" className={classes.socialButton} data-tooltip="Поделиться в VK">
                <VKIcon className={classes.socialIcon} round />
            </VKShareButton>
            <OKShareButton url={url} translate={null} className={classes.socialButton} data-tooltip="Поделиться в OK">
                <OKIcon className={classes.socialIcon} round />
            </OKShareButton>
            <TwitterShareButton url={url} className={classes.socialButton} data-tooltip="Поделиться в Twitter">
                <TwitterIcon className={classes.socialIcon} round />
            </TwitterShareButton>
            <WhatsappShareButton url={url} className={classes.socialButton} data-tooltip="Поделиться в WhatsApp">
                <WhatsappIcon className={classes.socialIcon} round />
            </WhatsappShareButton>
        </div>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        socialContainer: {
            display: 'flex',
            justifyContent: 'center',
            marginBottom: 32,
            marginTop: 32,
        },
        socialButton: {
            position: 'relative',

            '&:not(:last-child)': {
                marginRight: 32,
            },

            '&:before, &:after': {
                visibility: 'hidden',
            },

            '&:hover:before, &:hover:after, &:focus:before': {
                visibility: 'visible',
            },

            '&:before': {
                position: 'absolute',
                bottom: 100 + '%',
                left: 50 + '%',
                marginBottom: 5,
                marginLeft: -85,
                padding: 7,
                width: 160,
                borderRadius: 3,
                backgroundColor: '#0088AF',
                color: '#fff',
                content: 'attr(data-tooltip)',
                textAlign: 'center',
                fontSize: 14,
                lineHeight: 1.2,
            },

            '&:after': {
                position: 'absolute',
                bottom: 100 + '%',
                left: 50 + '%',
                marginLeft: -5,
                width: 0,
                borderTop: '5px solid #0088AF',
                borderRight: '5px solid transparent',
                borderLeft: '5px solid transparent',
                content: '""',
                fontSize: 0,
                lineHeight: 0,
            },
        },
        socialIcon: {
            display: 'block',
            width: 50,
            height: 50,

            [theme.breakpoints.down('sm')]: {
                width: 35,
                height: 35,
            },
        },
    });

export default injectSheet(styles, 'SocialShare')(SocialShare);
