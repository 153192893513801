import { remCalc } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { debounce } from 'core/utils';
import React, { useRef } from 'react';
import SearchIcon from '../../svg/search.svg';

type ISearchBoxProps = {
    search: string;
    onChange: (search: string) => void;
    placeholder?: string;
    debounceDelay?: number;
    showSearchButton?: boolean;
} & WithStyles<typeof styles>;

const SearchBox: React.FC<ISearchBoxProps> = ({
    onChange,
    search,
    placeholder = 'Искать...',
    debounceDelay = 500,
    classes,
    showSearchButton = true,
}) => {
    const input = useRef<HTMLInputElement>();
    const debounceOnChange = debounce(onChange, debounceDelay);
    const handleChange = () => {
        debounceOnChange(input.current.value);
    };

    return (
        <div className={classes.searchBox}>
            <input
                ref={input}
                name="q"
                type="search"
                className={classes.text}
                placeholder={placeholder}
                defaultValue={search}
                onChange={handleChange}
            />
            <SearchIcon className={classes.searchIcon} />
            {showSearchButton && (
                <button className={classes.submit} type="submit">
                    Поиск
                </button>
            )}
            {search ? (
                <button
                    className={classes.reset}
                    style={{ right: showSearchButton ? 84 : 6 }}
                    aria-label="Очистить строку поиска"
                    type="button"
                    title="Очистить строку поиска"
                    onClick={() => {
                        input.current.value = '';
                        onChange(undefined);
                    }}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            ) : null}
        </div>
    );
};

export const styles = (theme: ITheme) =>
    createStyles({
        searchBox: {
            display: 'flex',
            justifyContent: 'flex-end',
            position: 'relative',
            alignItems: 'center',
        },
        text: {
            fontSize: 14,
            border: '1px solid rgb(0, 0, 0, 0.1)',
            height: 40,
            boxSizing: 'border-box',
            width: 100 + '%',
            paddingLeft: 40,

            '&:focus': {
                border: '1px solid rgb(0, 0, 0, 0.5)',
            },
        },
        searchIcon: {
            position: 'absolute',
            width: 18,
            height: 18,
            fill: theme.colors.accent,
            left: 12,
        },
        submit: {
            margin: 0,
            display: 'block',
            padding: '5px 20px',
            background: theme.colors.accent,
            fontWeight: 'bold',
            color: 'white',
            border: 'none',
            fontSize: remCalc(15),
            alignSelf: 'stretch',

            '&:hover, &:focus': {
                backgroundColor: '#990000',
            },
        },
        reset: {
            position: 'absolute',
            border: 'none',
            backgroundColor: 'transparent',
            cursor: 'pointer',
            color: '#8a8a8a',
            fontSize: 18,
        },
    });

export default injectSheet(styles)(SearchBox);
