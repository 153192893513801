import c from 'classnames';
import { LinksConsumer } from 'core/components/linksContext';
import { button, buttonAccent, title } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles, ITheme } from 'core/style/interfaces';
import { isMobile } from 'core/utils';
import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import compose from 'recompose/compose';

const styles = (theme: ITheme) =>
    createStyles({
        background: {
            backgroundImage: `url(${require('./not-found.png')})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            position: 'absolute',
            top: 0,
            width: 100 + '%',
            height: 100 + '%',
            display: 'flex',
            justifyContent: 'center',
        },
        backgroundMobile: {
            backgroundImage: 'none',

            '& $root': {
                maxHeight: 60 + '%',
                width: 75 + '%',
            },
            '& $title': {
                color: theme.colors.contrastBg,
                marginBottom: 0.5 + 'rem',
            },
            '& $subtitle': {
                color: theme.colors.contrastBg,
                marginBottom: 1 + 'rem',
            },
            '& $button': {
                alignSelf: 'center',
                width: 40 + '%',
            },
        },
        root: {
            minHeight: '50vh',
            maxHeight: 75 + '%',
            maxWidth: 400,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'center',
            fontFamily: 'Arial, Helvetica, sans-serif',
        },
        title: {
            ...title({ size: 'h1' }),
            color: theme.colors.lightBg,
            marginBottom: 2 + 'rem',
            textTransform: 'uppercase',
        },
        subtitle: {
            ...title({ size: 'h3' }),
            color: theme.colors.lightBg,
            fontWeight: 'normal',
            marginBottom: 3 + 'rem',
        },
        button: {
            ...button(theme),
            ...buttonAccent(theme),
            fontWeight: 'bold',
        },
    });

class NotFound extends React.Component<IInnerProps> {
    UNSAFE_componentWillMount() {
        const { staticContext } = this.props;
        if (staticContext) {
            staticContext.statusCode = 404;
        }
    }

    render() {
        const { classes, customContent } = this.props;

        if (customContent) {
            return customContent;
        }

        return (
            <LinksConsumer>
                {links => (
                    <div className={c(classes.background, isMobile() ? classes.backgroundMobile : '')}>
                        <div className={classes.root}>
                            <h1 className={classes.title}>Ошибка 404</h1>
                            {isMobile() && (
                                <h3 className={classes.subtitle} style={{ fontWeight: 'bold' }}>
                                    СТРАНИЦА НЕ НАЙДЕНА
                                </h3>
                            )}
                            <h3 className={classes.subtitle}>
                                Неправильно набран адрес или такой страницы не существует
                            </h3>
                            {
                                <links.home.Link to={links.home.url()} className={classes.button}>
                                    {isMobile() ? 'На главную' : 'Перейти на главную'}
                                </links.home.Link>
                            }
                        </div>
                    </div>
                )}
            </LinksConsumer>
        );
    }
}

type IOutterProps = {
    customContent?: JSX.Element;
};
type IInnerProps = WithStyles<typeof styles> & RouteComponentProps & IOutterProps;

export default compose<IInnerProps, IOutterProps>(
    withRouter,
    injectSheet(styles, 'NotFound'),
)(NotFound);
