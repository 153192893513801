import { useRouteMatch } from 'react-router';

/**
 * Получить идентификаторы из урла
 * @description При неудачном парсинге числа в Id будет 0
 * @example '/courses/123' => { courseId: 123 }
 * @example '/courses/favicon.ico' => { courseId: 0 }
 * @example '/courses/new' => { courseId: 0 }
 * @example const { courseId } = useIdsFromUrl('courseId');
 */
export function useIdsFromUrl<T extends string>(...areas: T[]): Record<T, number> {
    const match = useRouteMatch<Record<T, string>>();

    return areas.reduce<Record<T, number>>((result, area) => {
        result[area] = parseInt(match.params[area], 10) || 0;
        return result;
    }, {} as Record<T, number>);
}

/**
 * Получить идентификаторы из урла без конвертации в число
 * @example '/courses/123' => { courseId: '123' }
 * @example '/courses/favicon.ico' => { courseId: 'favicon.ico' }
 * @example '/courses/new' => { courseId: 'new' }
 * @example const { courseId } = useIdsFromUrlWithoutParse('courseId');
 */
export function useIdsFromUrlWithoutParse<T extends string>(...areas: T[]): Record<T, string> {
    const match = useRouteMatch<Record<T, string>>();

    return areas.reduce<Record<T, string>>((result, area) => {
        result[area] = match.params[area];
        return result;
    }, {} as Record<T, string>);
}
