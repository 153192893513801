import { useLinks } from 'core/components/linksContext';
import Modal from 'core/components/Modal';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React, { useEffect } from 'react';
import SubscribeButton from 'site/components/subscription/SubscribeButton';
import { SubscriptionPublicTypeEnum } from 'site/graphql';
import AuthForm from '../auth/auth-form/AuthForm';
import { useAuth } from '../auth/AuthProvider';

type ISubscriptionModalProps = {
    isOpen: boolean;
    onClose: () => void;
    hasSubscription: boolean;
} & WithStyles<typeof styles>;

const SubscriptionModal: React.FC<ISubscriptionModalProps> = ({ isOpen, onClose, classes, hasSubscription }) => {
    const links = useLinks();
    const { isLoggedIn, setState } = useAuth();

    useEffect(() => {
        if (!isLoggedIn) {
            setState('login');
        }

        if (isOpen && isLoggedIn && hasSubscription) {
            onClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen, isLoggedIn, hasSubscription]);

    return (
        <Modal label="Оформление подписки" open={isOpen} onClose={onClose}>
            {isLoggedIn ? (
                <div className={classes.modal}>
                    <p className={classes.title}>Сервис работает по подписке*</p>
                    <SubscribeButton
                        type={SubscriptionPublicTypeEnum.Year}
                        label="100 рублей в месяц"
                        subLabel="при оплате за 12 месяцев, итого 1200 рублей"
                        className={classes.subscribeButton}
                    />
                    <SubscribeButton
                        type={SubscriptionPublicTypeEnum.Month}
                        label="200 рублей в месяц"
                        subLabel="при оплате за 1 месяц"
                        className={classes.subscribeButton}
                    />
                    <p className={classes.disclaimer}>
                        *Подробнее об условиях подписки в разделе{' '}
                        <links.account.Link className={classes.disclaimer} to={`${links.account.url()}subscriptions`}>
                            <b>Мои подписки в Личном кабинете</b>
                        </links.account.Link>
                    </p>
                </div>
            ) : (
                <AuthForm showRememberMe showSocialLogin />
            )}
        </Modal>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        modal: {
            backgroundColor: 'white',
            padding: 2 + 'rem',
            paddingBottom: 0,
            width: 20 + 'vw',

            [theme.breakpoints.down('sm')]: {
                width: 'calc(100vw - 4rem)',
                height: 'calc(100vh - 2rem)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
            },
        },
        title: {
            fontSize: 1 + 'rem',
            fontWeight: 'bold',
            textAlign: 'center',
            color: theme.colors.contrastBg,
        },
        subscribeButton: {
            width: 70 + '%',
            display: 'block',
            margin: '0 auto 0.5rem auto',
            fontSize: 1 + 'rem',
            fontWeight: 'bold',

            '&:last-of-type': {
                marginBottom: 1 + 'rem',
            },

            [theme.breakpoints.down('xs')]: {
                width: 100 + '%',
            },
        },
        disclaimer: {
            fontSize: 0.8 + 'rem',
            textAlign: 'center',
            lineHeight: 1 + 'rem',
            color: theme.colors.contrastBg,
        },
    });

export default injectSheet(styles)(SubscriptionModal);
