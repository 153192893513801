import { overwriteQuery, parseLocationQuery } from 'core/utils';
import { useHistory, useLocation } from 'react-router';

/**
 * Получение/изменение параметров в query
 * @example Usage: const { query, changeQuery } = useQuery();
 * @example query: '/courses?order=DATE&productId=123' => { order: 'DATE', productId: '123' }
 */
function useQuery<T = Record<string, any>>() {
    const location = useLocation();
    const history = useHistory();

    const query: T = parseLocationQuery(location);

    const changeQuery = (newQuery: T) => {
        overwriteQuery(history, location, newQuery, false);
    };

    return { query, changeQuery };
}

export default useQuery;
