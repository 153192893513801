import c from 'classnames';
import Modal from 'core/components/Modal';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { formatNumberToDecimal, formatToLocalDayMonthYearDot } from 'core/utils';
import React from 'react';
import { HighLowSecurity } from 'site/graphql';

type ISecurityInfoModalProps = {
    security: Pick<
        HighLowSecurity,
        'lowDate' | 'highDate' | 'name' | 'lowPercent' | 'highPercent' | 'current' | 'low' | 'high'
    >;
    isOpen: boolean;
    onCloseModal: () => void;
};

const SecurityInfoModal: React.StatelessComponent<ISecurityInfoModalProps & WithStyles<typeof styles>> = ({
    classes,
    isOpen,
    onCloseModal,
    security,
}) => {
    if (!security) {
        return null;
    }

    const { lowDate, highDate, name, lowPercent, highPercent, current, low, high } = security;
    const toLow = ((current - low) / current) * 100;
    const toHigh = ((high - current) / current) * 100;

    return (
        <Modal label="Информация об акции" open={isOpen} onClose={onCloseModal}>
            <div className={classes.modal}>
                <div className={classes.name}>{name}</div>
                <div>Дата обновления low: {formatToLocalDayMonthYearDot(lowDate)}</div>
                <div>Дата обновления high: {formatToLocalDayMonthYearDot(highDate)}</div>
                {highPercent && (
                    <div>
                        Упало от high:{' '}
                        <span
                            className={c({
                                [classes.percentRed]: highPercent < 0,
                                [classes.percentGreen]: highPercent > 0,
                            })}
                        >
                            {formatNumberToDecimal(highPercent)}%
                        </span>
                    </div>
                )}
                {lowPercent && (
                    <div>
                        Выросло от low:{' '}
                        <span
                            className={c({
                                [classes.percentRed]: lowPercent < 0,
                                [classes.percentGreen]: lowPercent > 0,
                            })}
                        >
                            {formatNumberToDecimal(lowPercent)}%
                        </span>
                    </div>
                )}
                <div>До low от текущей цены: {formatNumberToDecimal(toLow)}%</div>
                <div>До high от текущей цены: {formatNumberToDecimal(toHigh)}%</div>
            </div>
        </Modal>
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        modal: {
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: 2 + 'rem',
            paddingBottom: 1 + 'rem',
            fontSize: 1.2 + 'rem',
            color: theme.colors.black,
            lineHeight: 2 + 'rem',

            [theme.breakpoints.down('sm')]: {
                width: 'calc(100vw - 4rem)', // весь экран - 3*padding
                height: 'calc(100vh - 3rem)', // весь экран - 3*padding
                justifyContent: 'center',
            },
        },
        name: {
            textAlign: 'center',
            fontWeight: 'bold',
        },
        percentRed: {
            color: theme.colors.accent,
        },
        percentGreen: {
            color: theme.colors.green,
        },
    });

export default injectSheet(styles)(SecurityInfoModal);
