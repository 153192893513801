import {
    FinancialCalculatorGetCurrencyEnum,
    FinancialCalculatorGetPeriodEnum,
    FinancialCalculatorGetProfitEnum,
    FinancialCalculatorGetTypeEnum,
    FinancialCalculatorQuestionGetPeriodEnum,
} from 'site/graphql';

export const validatePositiveNumber = (text: string): boolean => !!parseInt(text, 10) && parseInt(text, 10) > 0;
export const validatePercent = (text: string): boolean => validatePositiveNumber(text) && parseInt(text, 10) <= 100;

export const mapProfit: Record<FinancialCalculatorGetProfitEnum, string> = {
    ANNUAL: 'ежегодный',
    MONTHLY: 'ежемесячный',
};

export const mapProfitAdverb: Record<FinancialCalculatorGetProfitEnum, string> = {
    ANNUAL: 'ежегодно',
    MONTHLY: 'ежемесячно',
};

export const mapPeriod: Record<FinancialCalculatorGetPeriodEnum, string> = {
    MONTH: 'месяц',
    YEAR: 'год',
};

export const mapCurrency: Record<FinancialCalculatorGetCurrencyEnum, string> = {
    RUB: 'рублей',
    USD: 'долларов',
};

export const mapCurrencyIcone: Record<FinancialCalculatorGetCurrencyEnum, string> = {
    RUB: '₽',
    USD: '$',
};

export const mapType: Record<FinancialCalculatorGetTypeEnum, string> = {
    CURRENT: 'с учетом',
    FUTURE: 'без учета',
};

export const mapPeriodInvestment: Record<FinancialCalculatorQuestionGetPeriodEnum, string> = {
    MONTH: 'каждый месяц',
    BI_WEEKLY: 'каждые 2 недели',
};
