import c from 'classnames';
import ExpandButton from 'core/components/ExpandButton';
import propsFromUrl from 'core/enhancers/propsFromUrl';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import { isMobile, numEnding } from 'core/utils';
import React from 'react';
import { compose } from 'recompose';

const styles = createStyles({
    container: {
        display: 'flex',
    },
    containerActive: {
        '&:before': {
            content: '""',
            position: 'absolute',
            zIndex: 1,
            top: '-1px',
            left: '-1px',
            right: '-1px',
            bottom: '0px',
            height: 5,
            background: '#cb0d26',
        },
    },
    title: {
        flex: 1,
        textAlign: 'center',
        fontWeight: 600,
    },
});

type IStyle = { hollow: boolean };

class DropDownTagsFilterButton extends React.PureComponent<IInnerProps> {
    link: HTMLDivElement;

    handleClick = e => {
        e.preventDefault();

        const bodyRect = document.body.getBoundingClientRect();
        const linkRect = this.link.parentElement.getBoundingClientRect();

        this.props.onClick({
            domElement: this.link,
            offset: {
                top: linkRect.top - bodyRect.top,
                bottom: linkRect.bottom - bodyRect.top,
                left: linkRect.left,
                right: linkRect.right,
            },
            width: linkRect.width,
            height: linkRect.height - 2, // minus border
        });
    };

    render() {
        const { title, classes, urlTags, show } = this.props;
        const selectedTagsCount = urlTags ? (Array.isArray(urlTags) ? urlTags.length : 1) : 0;

        return (
            <div
                ref={a => (this.link = a)}
                className={c(classes.container, {
                    [classes.containerActive]: selectedTagsCount !== 0,
                })}
                title={title}
                onClick={this.handleClick}
            >
                <div className={classes.title}>
                    {urlTags
                        ? `Выбран${
                              selectedTagsCount > 1 ? 'о' : 'а'
                          } ${selectedTagsCount} ${numEnding(selectedTagsCount, ['тема', 'темы', 'тем'])}`
                        : 'Выберите тему'}
                </div>
                {!isMobile() && <ExpandButton isDownDirection={!show} />}
            </div>
        );
    }
}

export type IOutterProps = {
    onClick: (obj) => void;
    title?: string;
    name: string;
    style?: IStyle;
    show: boolean;
};

export type IInnerProps = {
    urlTags: string | string[];
} & IOutterProps &
    WithStyles<typeof styles>;

export default compose<IInnerProps, IOutterProps>(
    propsFromUrl({ query: [{ tags: 'urlTags' }] }),
    injectSheet(styles),
)(DropDownTagsFilterButton);
