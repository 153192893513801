import c from 'classnames';
import { getPictureUrl } from 'core/api/pictures';
import separatedElements from 'core/components/common/separatedElements';
import { LinksConsumer } from 'core/components/linksContext';
import { DEFAULT_SOON_CAUSE } from 'core/const';
import { button } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import {
    formatToLocalDayMonth,
    formatToLocalDayMonthYearDot,
    isFilledArray,
    isImpossibleDate,
    priceToString,
} from 'core/utils';
import { getFirstSeminar, getFirstWebinar } from 'core/utils/product-forms/orderProductForms';
import { getFirstEventRecord } from 'core/utils/product-forms/productForms';
import React from 'react';
import { Account, EventRecord, Lecturer, Product, ProductForm, Seminar, Webinar } from 'site/graphql';
import { IWithSettingsProps, withSettings } from '../../../core/components/settingsContext';
import LecturerIcon from '../../svg/lecturer.svg';
import PayButtonContainer from '../payments/PayButtonContainer';
import { IPayProductForm } from '../payments/PayButtonPresent';
import ProductFormsIcons from './ProductFormsIcons';

export const titleLineHeight = 1.5;
export const shortDescLineHeight = 1.2;

export type IProductFormCardProductForm = IPayProductForm &
    Pick<ProductForm, 'type' | 'showNumberLessons'> & {
        webinars: Pick<Webinar, 'date' | 'soonCause'>[];
        seminars: Pick<Seminar, 'date' | 'soonCause'>[];
        records: Pick<EventRecord, 'date'>[];
        withMeInSetForms: Pick<ProductForm, 'id' | 'type'>[];
        product: {
            lecturers: ({
                account: Pick<Account, 'id' | 'firstName' | 'lastName'>;
            } & Pick<Lecturer, 'id'>)[];
        } & Pick<Product, 'id' | 'name' | 'pictureId' | 'shortDescription'>;
    };

export interface IProductFormCardProps {
    productForm: IProductFormCardProductForm;
    isDark: boolean;
    mode: 'FULL' | 'ON_DETAIL' | 'IN_CART' | 'MAIN_PAGE';
}

interface InfoProps {
    date: string | Date;
    classes: any;
    soonCause?: string;
    count: number;
    mode: string;
    showNumberLessons: boolean;
}

const InfoComponent: React.FC<InfoProps> = ({ date, count, soonCause, classes, mode, showNumberLessons }) =>
    isImpossibleDate(date) ? (
        <div className={classes.soonDateText}>{soonCause || DEFAULT_SOON_CAUSE}</div>
    ) : (
        <div className={classes.dates}>
            {mode === 'MAIN_PAGE' ? (
                <span>с {formatToLocalDayMonth(date)}</span>
            ) : (
                <span>
                    С {formatToLocalDayMonth(date)}
                    <br />
                    {showNumberLessons && <span className={classes.lessonsCount}>Занятий: {count}</span>}
                </span>
            )}
        </div>
    );

class ProductFormCard extends React.PureComponent<
    IProductFormCardProps & IWithSettingsProps & WithStyles<typeof styles>
> {
    static defaultProps = {
        isDark: false,
    };

    renderInfo() {
        const { classes, productForm, mode } = this.props;
        const { webinars, seminars, records } = productForm;
        let timeInfo = null;
        let costText = null;

        const firstWebinar = getFirstWebinar(webinars);
        const firstSeminar = getFirstSeminar(seminars);

        if (productForm.type === 'OFFER' && (webinars.length > 0 || seminars.length > 0)) {
            timeInfo = (
                <InfoComponent
                    date={(firstWebinar || firstSeminar).date}
                    soonCause={(firstWebinar || firstSeminar).soonCause}
                    count={webinars.length + seminars.length}
                    classes={classes}
                    mode={mode}
                    showNumberLessons={productForm.showNumberLessons}
                />
            );
        } else if (webinars.length > 0) {
            timeInfo = (
                <InfoComponent
                    date={firstWebinar.date}
                    soonCause={firstWebinar.soonCause}
                    count={webinars.length}
                    classes={classes}
                    mode={mode}
                    showNumberLessons={productForm.showNumberLessons}
                />
            );
        } else if (seminars.length > 0) {
            timeInfo = (
                <InfoComponent
                    date={firstSeminar.date}
                    soonCause={firstSeminar.soonCause}
                    count={seminars.length}
                    classes={classes}
                    mode={mode}
                    showNumberLessons={productForm.showNumberLessons}
                />
            );
        } else if (records.length > 0 && records.some(record => !!record.date)) {
            const firstEventRecordDate = getFirstEventRecord(records).date;

            timeInfo = !isImpossibleDate(firstEventRecordDate) && (
                <div className={classes.dates}>
                    От {formatToLocalDayMonthYearDot(firstEventRecordDate)}
                    <br />
                    {productForm.showNumberLessons && (
                        <span className={classes.lessonsCount}>Занятий: {records.length}</span>
                    )}
                </div>
            );
        }

        if (mode !== 'MAIN_PAGE') {
            costText = (
                <span>
                    Стоимость:
                    <br />
                </span>
            );
        }

        return (
            <div className={mode === 'MAIN_PAGE' ? classes.infoOnMain : classes.info}>
                <div>
                    {costText}
                    <span className={classes.price}>
                        {productForm.price === 0 ? 'БЕСПЛАТНО' : priceToString(productForm.price)}
                    </span>
                </div>
                {timeInfo}
            </div>
        );
    }

    renderIncludes() {
        const { mode, classes, productForm } = this.props;
        const { type, withMeInSetForms } = productForm;

        switch (mode) {
            case 'ON_DETAIL':
                return (
                    <div className={classes.includes}>
                        <ProductFormsIcons productForms={[productForm]} />
                        {withMeInSetForms && withMeInSetForms.length > 0 && (
                            <span className={classes.includesTitle}>Также включает в себя:</span>
                        )}
                        <ProductFormsIcons productForms={withMeInSetForms} />
                    </div>
                );
            default:
                return (
                    <div className={classes.includes}>
                        {type !== 'OFFER' && <span className={classes.includesTitle}>Включает в себя:</span>}
                        <ProductFormsIcons
                            productForms={[type !== 'OFFER' ? productForm : null, ...withMeInSetForms].filter(Boolean)}
                        />
                    </div>
                );
        }
    }

    renderHeader() {
        const { classes, productForm, mode, settings } = this.props;
        const { product } = productForm;

        return (
            <LinksConsumer>
                {links => (
                    <div
                        className={classes.media}
                        title={product.name}
                        style={{
                            background: `url("${getPictureUrl(
                                product.pictureId,
                                '370x180',
                                settings,
                            )}") center center / cover no-repeat`,
                            backgroundColor: '#333333',
                        }}
                    >
                        <links.productDetail.Link
                            className={classes.mediaLink}
                            to={links.productDetail.url(product.id)}
                        />
                        {mode !== 'MAIN_PAGE' && (
                            <div className={classes.header}>
                                {isFilledArray(product.lecturers) && (
                                    <div className={classes.lecturerWrap}>
                                        <LecturerIcon className={classes.lecturerIcon} />
                                        {separatedElements(product.lecturers, lecturer => (
                                            <links.teacherDetail.Link
                                                key={lecturer.id}
                                                title={lecturer.account.firstName + ' ' + lecturer.account.lastName}
                                                className={c(classes.lecturer, classes.lecturerLink)}
                                                to={links.teacherDetail.url(lecturer.id)}
                                            >
                                                {lecturer.account.firstName} {lecturer.account.lastName}
                                            </links.teacherDetail.Link>
                                        ))}
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </LinksConsumer>
        );
    }

    renderShortDescription() {
        const { classes, productForm } = this.props;
        const { product } = productForm;

        return (
            <p className={classes.shortDescription} title={product.shortDescription}>
                {product.shortDescription}
            </p>
        );
    }

    renderLecturers() {
        const { classes, productForm } = this.props;
        const { product } = productForm;

        return (
            isFilledArray(product.lecturers) && (
                <div className={classes.lecturerWrap}>
                    <LecturerIcon className={classes.lecturerIcon} />
                    {separatedElements(product.lecturers, lecturer => (
                        <span key={lecturer.id} className={classes.lecturer}>
                            {lecturer.account.firstName} {lecturer.account.lastName}
                        </span>
                    ))}
                </div>
            )
        );
    }

    render() {
        const { classes, productForm, isDark, mode } = this.props;
        const { product } = productForm;

        switch (mode) {
            case 'ON_DETAIL':
                return (
                    <div className={c(classes.card, isDark ? classes.cardDark : '')}>
                        <div className={classes.content}>
                            <span className={classes.title}>{productForm.title}</span>
                            {this.renderIncludes()}
                            {this.renderInfo()}
                            <PayButtonContainer productForm={productForm} />
                        </div>
                    </div>
                );
            case 'IN_CART':
                return (
                    <LinksConsumer>
                        {links => (
                            <div className={c(classes.card, isDark ? classes.cardDark : '')}>
                                <div className={classes.content}>
                                    <div className={classes.descWrap}>
                                        <a
                                            href={links.productDetail.url(product.id)}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className={classes.title}
                                        >
                                            {product.name}
                                        </a>
                                        {this.renderLecturers()}
                                        {this.renderShortDescription()}
                                    </div>
                                    {this.renderIncludes()}
                                    {this.renderInfo()}
                                    <PayButtonContainer productForm={productForm} />
                                </div>
                            </div>
                        )}
                    </LinksConsumer>
                );
            case 'MAIN_PAGE':
                return (
                    <LinksConsumer>
                        {links => (
                            <div className={classes.card}>
                                {this.renderHeader()}
                                <div className={classes.content}>
                                    {isFilledArray(product.lecturers) && (
                                        <div className={classes.lecturerWrap}>
                                            {separatedElements(product.lecturers, lecturer => (
                                                <links.teacherDetail.Link
                                                    key={lecturer.id}
                                                    title={lecturer.account.firstName + ' ' + lecturer.account.lastName}
                                                    className={c(classes.lecturer, classes.lecturerLink)}
                                                    to={links.teacherDetail.url(lecturer.id)}
                                                >
                                                    {lecturer.account.firstName} {lecturer.account.lastName}
                                                </links.teacherDetail.Link>
                                            ))}
                                        </div>
                                    )}
                                    <div className={classes.descWrapMainPage}>
                                        <links.productDetail.Link
                                            to={links.productDetail.url(product.id)}
                                            className={classes.title}
                                        >
                                            {product.name}
                                        </links.productDetail.Link>
                                    </div>

                                    {this.renderInfo()}
                                    <div>
                                        <PayButtonContainer productForm={productForm} />
                                    </div>
                                </div>
                            </div>
                        )}
                    </LinksConsumer>
                );
            case 'FULL':
            default:
                return (
                    <LinksConsumer>
                        {links => (
                            <div className={c(classes.card, isDark ? classes.cardDark : '')}>
                                {this.renderHeader()}
                                <div className={classes.content}>
                                    <div className={classes.descWrap}>
                                        <links.productDetail.Link
                                            to={links.productDetail.url(product.id)}
                                            className={classes.title}
                                        >
                                            {product.name}
                                        </links.productDetail.Link>
                                        {this.renderShortDescription()}
                                    </div>
                                    <links.productDetail.Link
                                        to={links.productDetail.url(product.id)}
                                        className={classes.more}
                                    >
                                        Узнать больше
                                    </links.productDetail.Link>
                                    {this.renderIncludes()}
                                    {this.renderInfo()}
                                    <PayButtonContainer productForm={productForm} />
                                </div>
                            </div>
                        )}
                    </LinksConsumer>
                );
        }
    }
}

const styles = (theme: ITheme) =>
    createStyles({
        card: {
            flexGrow: 1,
            fontFamily: 'Arial, sans-serif',
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: theme.colors.lightBg,
            boxShadow: '0 2px 10px 0 rgba(0, 0, 0, 0.27)',
            boxSizing: 'border-box',
            width: '100%',
        },
        cardDark: {
            backgroundColor: theme.colors.contrastBg,
            '& $descWrap:after, & $title:after': {
                background:
                    'linear-gradient(to bottom,' + 'rgba(30, 37, 47, 0)' + ' 0%, ' + theme.colors.contrastBg + ' 70%)',
                fallbacks: [
                    {
                        background:
                            '-moz-linear-gradient(top,' +
                            'rgba(30, 37, 47, 0)' +
                            ' 0%, ' +
                            theme.colors.contrastBg +
                            ' 70%)' /* FF3.6-15 */,
                    },
                    {
                        background:
                            '-webkit-linear-gradient(top,' +
                            'rgba(30, 37, 47, 0)' +
                            ' 0%, ' +
                            theme.colors.contrastBg +
                            ' 70%)' /* Chrome10-25,Safari5.1-6 */,
                    },
                ],
            },

            '& $title, & $content': {
                color: theme.colors.contrastBgText,
            },
            '& $title': {
                '&:hover, &:focus': {
                    color: theme.colors.contrastBgMedium,
                },
            },
            '& $content $lecturer': {
                color: theme.colors.contrastBgText,
            },
            '& $content': {
                borderBottom: '1px solid ' + theme.colors.contrastBgText,

                '&:hover, &:focus': {
                    color: theme.colors.contrastBgMedium,
                    borderBottomColor: theme.colors.contrastBgMedium,
                },
            },
            '& $includes': {
                color: theme.colors.contrastBgMedium,
            },
            '& $more': {
                color: theme.colors.accentLight,

                '&:hover, &:focus': {
                    color: theme.colors.secondary,
                    borderBottomColor: theme.colors.secondary,
                },
            },
        },
        media: {
            display: 'flex',
            alignItems: 'flex-end',
            height: 180,
            // flexShrink - Safari and IE
            flexShrink: 100,
            backgroundColor: '#FFF',
            textDecoration: 'none',
            position: 'relative',
        },
        mediaLink: {
            position: 'absolute',
            left: 0,
            top: 0,
            height: 100 + '%',
            width: 100 + '%',
            zIndex: 0,
        },
        header: {
            flexGrow: 1,
            backgroundColor: 'rgba(255, 255, 255, 0.65)',
            padding: '0.5rem 1.2rem',
            fontSize: 14,
            width: '100%',
            boxSizing: 'border-box',
            zIndex: 1,
        },
        lecturerWrap: {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            maxWidth: '100%',
        },
        lecturerIcon: {
            display: 'inline-block',
            width: 0.8 + 'rem',
            height: 0.8 + 'rem',
            fill: theme.colors.accentLight,
            marginRight: 0.5 + 'rem',
        },
        lecturer: {
            textDecoration: 'none',
            color: theme.colors.textBase,
            lineHeight: 1.25 + 'rem',
            fontSize: 0.89 + 'rem',
        },
        lecturerLink: {
            '&:hover, &:focus': {
                color: theme.colors.textMedium,
            },
        },
        content: {
            padding: '1.2rem',
            paddingTop: '0.8rem',
            display: 'flex',
            flexDirection: 'column',
            flexGrow: 1,
            boxSizing: 'border-box',
            maxWidth: '100%',
            maxHeight: '100%',

            '& > *': {
                marginBottom: 0.5 + 'rem',
            },

            '& > :last-child': {
                marginBottom: 0,
            },
        },
        descWrap: {
            position: 'relative',
            marginBottom: 0,
            flexGrow: 1,
            height: titleLineHeight * 3.5 + shortDescLineHeight * 3.5 + 'rem',
            maxHeight: titleLineHeight * 3.5 + shortDescLineHeight * 3.5 + 'rem',
            overflow: 'hidden',
            paddingBottom: shortDescLineHeight / 2 + 'rem',

            '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -1,
                left: 0,
                width: '100%',
                height: shortDescLineHeight + 'rem',
                background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #FFF 70%)',
                fallbacks: [
                    {
                        background:
                            '-moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%)' /* FF3.6-15 */,
                    },
                    {
                        background:
                            '-webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%)' /* Chrome10-25,Safari5.1-6 */,
                    },
                ],
            },
        },
        descWrapMainPage: {
            position: 'relative',
            marginBottom: 0,
            flexGrow: 1,
            overflow: 'hidden',
            paddingBottom: shortDescLineHeight / 2 + 'rem',

            '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -1,
                left: 0,
                width: '100%',
                height: shortDescLineHeight + 'rem',
                background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #FFF 70%)',
                fallbacks: [
                    {
                        background:
                            '-moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%)' /* FF3.6-15 */,
                    },
                    {
                        background:
                            '-webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%)' /* Chrome10-25,Safari5.1-6 */,
                    },
                ],
            },
        },
        title: {
            display: 'block',
            position: 'relative',
            fontFamily: 'Franklin Gothic Medium, Franklin Gothic, ITC Franklin Gothic, Arial, sans-serif',
            fontSize: 1.1 + 'rem',
            lineHeight: titleLineHeight + 'rem',
            color: theme.colors.textBase,
            textTransform: 'uppercase',
            textDecoration: 'none',
            maxHeight: titleLineHeight * 3.5 + 'rem',
            overflow: 'hidden',
            paddingBottom: titleLineHeight / 2 + 'rem',
            marginBottom: 0,

            '&:after': {
                content: '""',
                position: 'absolute',
                bottom: -1,
                left: 0,
                width: '100%',
                height: titleLineHeight + 'rem',
                background: 'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #FFF 70%)',
                fallbacks: [
                    {
                        background:
                            '-moz-linear-gradient(top, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 70%)' /* FF3.6-15 */,
                    },
                    {
                        background:
                            '-webkit-linear-gradient(top, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 70%)' /* Chrome10-25,Safari5.1-6 */,
                    },
                ],
            },
        },
        shortDescription: {
            position: 'relative',
            fontSize: 0.89 + 'rem',
            flexGrow: 1,
            lineHeight: shortDescLineHeight + 'rem',
            marginBottom: 0,
        },
        includes: {
            color: theme.colors.textMedium,
            flexGrow: 1,
        },
        includesTitle: {
            fontSize: 0.89 + 'rem',
            fontWeight: 700,
        },
        more: {
            alignSelf: 'center',
            marginBottom: 0.5 + 'rem',
            fontSize: 0.89 + 'rem',
            lineHeight: 1.3,
            color: theme.colors.accent,
            textDecoration: 'none',

            '&:hover, &:focus': {
                color: theme.colors.secondary,
                borderBottomColor: theme.colors.secondary,
            },
        },
        info: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            fontSize: 0.7 + 'rem',
            lineHeight: 1.3,
        },
        price: {
            fontWeight: 700,
            fontSize: 1 + 'rem',
            lineHeight: 1.3 + 'rem',
            fontFamily: '"Helvetica Neue", sans-serif',
        },
        dates: {
            textAlign: 'right',
            fontWeight: 700,
        },
        soonDateText: {
            fontWeight: 700,
            fontSize: 1 + 'rem',
            lineHeight: 1.3 + 'rem',
            position: 'relative',
            bottom: 0,
            textAlign: 'right',
        },
        lessonsCount: {
            lineHeight: 1.3 + 'rem',
        },
        actions: {
            display: 'flex',
            justifyContent: 'center',
        },
        openPaymentModalButton: {
            ...button(theme),
        },
        infoOnMain: {
            display: 'flex',
            justifyContent: 'space-between',
            fontSize: 0.7 + 'rem',
        },
    });

export default withSettings<IProductFormCardProps>(injectSheet(styles, 'ProductFormCard')(ProductFormCard));
