import { gql } from 'apollo-boost';
import React from 'react';
import { useGetTextBlockQuery } from 'site/graphql';

gql`
    query GetTextBlock($slug: String!, $withTextBlocks: Boolean) {
        pageBySlug(slug: $slug, withTextBlocks: $withTextBlocks) {
            id
            slug
            content
        }
    }
`;

type ITextBlockProps = {
    slug: string;
};

const TextBlock: React.FC<ITextBlockProps> = ({ slug }) => {
    const { data } = useGetTextBlockQuery({
        variables: { slug, withTextBlocks: true },
        errorPolicy: 'ignore',
    });
    const content = data && data.pageBySlug && data.pageBySlug.content;

    if (!content) {
        return null;
    }

    return <div dangerouslySetInnerHTML={{ __html: content }} />;
};

export default TextBlock;
