import { useState, useCallback } from 'react';

/**
 * Переключатель
 * @example const [toggle, { onToggle, offToggle, switchToggle }] = useToggle();
 */
const useToggle = () => {
    const [toggle, setToggle] = useState(false);

    const switchToggle = useCallback(() => setToggle(state => !state), [setToggle]);
    const onToggle = useCallback(() => setToggle(true), [setToggle]);
    const offToggle = useCallback(() => setToggle(false), [setToggle]);

    return [toggle, { onToggle, offToggle, switchToggle }] as const;
};

export default useToggle;
