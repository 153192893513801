import c from 'classnames';
import { createStyles } from 'core/style/injectSheet';
import { IStyledComponentProps } from 'core/style/interfaces';
import React from 'react';
import DefaultDropDownButton from './DropDownButton';

export const styles = createStyles({
    dropdown: {
        position: 'relative',
    },
    dropdownActive: {
        borderBottom: '#cc0000 4px solid',
    },
    dropdownList: {
        position: 'absolute',
        padding: '1rem',
        zIndex: 20,
        left: 0,
        background: '#1e252f',
        width: 367,
    },
});

type IDropDownProps = {
    show: boolean;
    close: () => void;
    id: string;
    listPosition?: { top: any };
    triggerClasses?: any;
} & React.ComponentProps<typeof DefaultDropDownButton> &
    IStyledComponentProps<typeof styles>;

interface IDropDownHocOptions {
    DropDownButton?: typeof DefaultDropDownButton;
}

const dropDownHoc = ({ DropDownButton = DefaultDropDownButton }: IDropDownHocOptions) => {
    class DropDown extends React.PureComponent<IDropDownProps> {
        list: any;

        closeIfClickOutside = e => {
            const { show, close, id, classes } = this.props;
            if (show && close) {
                const el1 = e.target.closest('.' + classes.dropdown);
                const el2 = e.target.closest('.' + classes.dropdownList);
                const elForceClose = e.target.closest('.js-force-close');

                if (
                    !['svg', 'use', 'path'].includes(e.target.tagName) &&
                    e.target.className.split(' ').some(className => className === 'js-nondissapear')
                )
                    return;
                if (elForceClose) return close();

                // Закрываем, если кликаем не в области данного элемента
                if (el1 && el1.id !== id) {
                    close();
                }
                // Закрываем, если кликаем снаружи или внутри, но только на ссылку или svg-иконку
                if (!el1 || (el2 && ['a', 'svg', 'use'].includes(e.target.tagName.toLowerCase()))) {
                    close();
                }
            }
        };

        componentDidMount() {
            document.addEventListener('click', this.closeIfClickOutside);
        }

        componentWillUnmount() {
            document.removeEventListener('click', this.closeIfClickOutside);
        }

        render() {
            const {
                children,
                show = false,
                listPosition = {} as { top: any },
                id,
                classes,
                triggerClasses,
                ...props
            } = this.props;
            const { top } = listPosition;

            return (
                <div className={c(classes.dropdown, { [classes.dropdownActive]: show })} id={id}>
                    <DropDownButton classes={triggerClasses} {...props} />
                    {show && (
                        <div ref={list => (this.list = list)} className={classes.dropdownList} style={{ top }}>
                            {children}
                        </div>
                    )}
                </div>
            );
        }
    }

    return DropDown;
};

export const DefaultDropDown = dropDownHoc({});
export default dropDownHoc;
