import { IControlProps } from 'core/components/carousel';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';
import ArrowLeftIcon from '../../../svg/arrow_left.svg';

const styles = createStyles({
    button: {
        display: 'inline-block',
        height: '1.1rem',
        width: '1.1rem',
        verticalAlign: 'middle',
        fill: '#fefefe',
        cursor: 'pointer',
        '&:hover': {
            fill: '#cccccc',
        },
    },
});

type IPreviousButtonProps = {
    customStyles?: React.CSSProperties;
    darkenWhenBoundary?: boolean;
} & IControlProps &
    WithStyles<typeof styles>;

export class PreviousButton extends React.PureComponent<IPreviousButtonProps> {
    handleClick = event => {
        event.preventDefault();
        this.props.previousSlide();
    };

    render() {
        const { classes, customStyles, darkenWhenBoundary = false, currentSlide } = this.props;
        const boundary = currentSlide === 0;
        const opacity = darkenWhenBoundary && boundary ? 0.1 : 1;
        const cursor = darkenWhenBoundary && boundary ? 'default' : 'pointer';

        return (
            <ArrowLeftIcon
                onClick={this.handleClick}
                className={classes.button}
                style={{ ...customStyles, opacity, cursor }}
            />
        );
    }
}

export default injectSheet(styles)(PreviousButton);
