import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';
import React from 'react';
import { GetDividendsListQuery } from 'site/graphql';

export type IDividendsListKeys = keyof GetDividendsListQuery['dividends'][0];

type IDividendTableHeadCellProps = {
    title: string;
    tooltip: string | JSX.Element;
    field?: IDividendsListKeys;
    direction?: 'asc' | 'desc';
    order?: IDividendsListKeys;
    setOrder?: (newOrder: IDividendsListKeys, newDirection: 'asc' | 'desc') => void;
    colSpan?: number;
    className?: string;
} & WithStyles<typeof styles>;

const DividendTableHeadCell: React.FC<IDividendTableHeadCellProps> = ({
    title,
    classes,
    direction,
    order,
    setOrder,
    field,
    tooltip,
    colSpan,
    className,
}) => {
    const sortable = field && direction && order && setOrder;
    const handleSort = () => {
        if (order === field) {
            setOrder(order, direction === 'asc' ? 'desc' : 'asc');
        } else {
            setOrder(field, 'desc');
        }
    };

    return (
        <th className={c(classes.root, className)} colSpan={colSpan}>
            <span className={classes.title}>{title}</span>
            <span className={classes.tooltip}>{tooltip}</span>
            {sortable && (
                <button
                    className={c(classes.sort, {
                        [classes.sortActive]: order === field,
                        [classes.sortAsc]: order === field && direction === 'asc',
                    })}
                    onClick={handleSort}
                ></button>
            )}
        </th>
    );
};

const styles = createStyles({
    root: {
        backgroundColor: 'white',
        position: 'sticky',
        top: 0,
        fontSize: 12,
        fontFamily: '"Helvetica Neue", sans-serif',
        lineHeight: 16 + 'px',
        color: '#808080',
        textAlign: 'left',
        verticalAlign: 'top',
        height: 100,
        minWidth: 50,
    },
    title: {
        borderBottom: '1px dashed #808080',

        '&:hover, &:focus': {
            '& + $tooltip': {
                display: 'block',
            },
        },
    },
    tooltip: {
        display: 'none',
        minWidth: 240,
        fontSize: 12,
        lineHeight: 16 + 'px',
        color: '#808080',
        backgroundColor: '#D9D9D9',
        position: 'absolute',
        top: 105 + '%',
        left: 22,
        padding: '10px 8px',
        textAlign: 'left',
        borderRadius: 5,

        '&::before': {
            content: '""',
            position: 'absolute',
            top: -12,
            left: -10,
            width: 0,
            height: 0,
            borderStyle: 'solid',
            borderWidth: '0 0 20px 10px',
            borderColor: 'transparent transparent transparent #D9D9D9',
            transform: 'rotate(125deg)',
        },
    },
    sort: {
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 0,
        position: 'absolute',
        right: 5,
        bottom: 2,
        height: 20,

        '&::before': {
            content: '""',
            border: 'solid #D9D9D9',
            borderWidth: '0px 2px 2px 0px',
            display: 'inline-block',
            padding: 4,
            transform: 'rotate(45deg)',
            marginBottom: 3,
        },
    },
    sortActive: {
        '&::before': {
            borderColor: '#808080',
        },
    },
    sortAsc: {
        '&::before': {
            transform: 'rotate(-135deg)',
            marginBottom: -3,
        },
    },
});

export default injectSheet(styles)(DividendTableHeadCell);
