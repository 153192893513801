import c from 'classnames';
import { getPictureUrl } from 'core/api/pictures';
import { LinksConsumer } from 'core/components/linksContext';
import { IWithSettingsProps, withSettings } from 'core/components/settingsContext';
import { column, row, title } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import React from 'react';
import { Account, Lecturer as LecturerContract, Product } from 'site/graphql';
import { isHtmlEmpty } from '../../../core/utils';
import LecturerProducts from './LecturerProducts';

export const getLecturerName = (account: ILecturerProps['lecturer']['account']) =>
    [account.firstName, account.middleName, account.lastName].filter(s => !!s).join(' ');

export interface ILecturerProps {
    lecturer: Pick<LecturerContract, 'id' | 'education' | 'skill' | 'about'> & {
        account: Pick<Account, 'id' | 'pictureId' | 'firstName' | 'middleName' | 'lastName'>;
        products: Pick<Product, 'id' | 'name'>[];
    };
    imageOrder?: boolean;
    style?: any;
    mode: 'LIST' | 'DETAIL';
}

class Lecturer extends React.PureComponent<ILecturerProps & IWithSettingsProps & WithStyles<typeof styles>> {
    renderInfoBlock = (blockTitle, html) => {
        const { classes } = this.props;
        if (isHtmlEmpty(html)) {
            return null;
        }

        return (
            <div className={classes.infoBlock}>
                <strong className={classes.infoBlock__title}>{blockTitle}:</strong>
                <div dangerouslySetInnerHTML={{ __html: html }} className={classes.infoBlock__text} />
            </div>
        );
    };

    render() {
        const { classes, lecturer, imageOrder, style, mode, settings } = this.props;
        const { account } = lecturer;
        const { products } = lecturer;
        const name = getLecturerName(account);

        return (
            <div className={classes.lecturer__row} style={style}>
                <div className={c(classes.photo, imageOrder ? classes.even : classes.odd)}>
                    <img className={classes.imageLoader} src={getPictureUrl(account.pictureId, '370x740', settings)} />
                </div>
                <div className={classes.about}>
                    {mode === 'LIST' ? (
                        <LinksConsumer>
                            {links => (
                                <links.teacherDetail.Link
                                    to={links.teacherDetail.url(lecturer.id)}
                                    className={classes.name}
                                >
                                    {name}
                                </links.teacherDetail.Link>
                            )}
                        </LinksConsumer>
                    ) : (
                        <p>{name}</p>
                    )}
                    {this.renderInfoBlock('Образование', lecturer.education)}
                    {this.renderInfoBlock('Опыт', lecturer.skill)}
                    {this.renderInfoBlock('О себе', lecturer.about)}
                    <LecturerProducts products={products} />
                </div>
            </div>
        );
    }
}

const styles = (theme: ITheme) =>
    createStyles({
        lecturer__row: {
            ...row(),
        },
        odd: {
            order: 0,
        },
        even: {
            order: 2,

            [theme.breakpoints.down('xs')]: {
                order: 0,
            },
        },
        photo: {
            ...column({ cols: 4 }),
            width: '100%',
            maxWidth: '100%',

            [theme.breakpoints.down('xs')]: {
                ...column(),
                marginBottom: 1.5 + 'rem',
            },
        },
        imageLoader: {
            border: '10px solid ' + theme.colors.lightGray,
            height: 'auto',
            width: '100%',
            maxWidth: '100%',
            boxSizing: 'border-box',
        },
        name: {
            ...title({ size: 'h3', color: theme.colors.linkOld }),
            marginBottom: '1.5rem',
            textDecoration: 'none',

            '&:hover, &:active, &:focus ': {
                color: theme.colors.linkOldHover,
            },

            [theme.breakpoints.down('sm')]: {
                fontSize: '1rem',
                marginBottom: '0.89rem',
                textAlign: 'center',
            },
        },
        about: {
            ...column({ cols: 8 }),
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxWidth: '100%',

            [theme.breakpoints.down('xs')]: {
                ...column(),
            },
        },
        infoBlock: {
            ...row(),
            marginBottom: 25,

            [theme.breakpoints.down('xs')]: {
                alignItems: 'center',
                fontSize: '0.89rem',
            },
        },
        infoBlock__title: {
            ...column({ cols: 3 }),

            [theme.breakpoints.down('xs')]: {
                ...column(),
                fontSize: '0.89rem',
            },
        },
        infoBlock__text: {
            ...column({ cols: 9 }),

            [theme.breakpoints.down('xs')]: {
                ...column(),
                textAlign: 'center',
                fontSize: '0.89rem',
            },
        },
    });

export default withSettings<ILecturerProps>(injectSheet(styles, 'Lecturer')(Lecturer));
