/* eslint-disable react-hooks/rules-of-hooks */
import { useTheme } from '@material-ui/styles';
import { ITheme } from 'core/style/interfaces';
import { useEffect, useState } from 'react';
import { ifBrowser } from 'core/utils';

export default function useMedia<T>(values: T[]) {
    if (!ifBrowser()) {
        return 3;
    }

    const theme = useTheme<ITheme>();
    const keys = [...theme.breakpoints.keys].reverse();

    const mediaQueryLists = keys.map(size =>
        window
            ? window.matchMedia(`(min-width: ${theme.breakpoints.values[size]}px)`)
            : { matches: true, addListener: () => null, removeListener: () => null },
    );

    const getValue = () => {
        const index = mediaQueryLists.findIndex(mql => mql.matches);
        return values[index];
    };

    const [breakpoint, setBreakpoint] = useState(getValue);

    useEffect(() => {
        const handler = () => setBreakpoint(getValue);
        mediaQueryLists.forEach(mql => mql.addListener(handler));
        return () => mediaQueryLists.forEach(mql => mql.removeListener(handler));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return breakpoint;
}
