import { column, row } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { isEmpty } from 'core/utils';
import React from 'react';
import { Product } from 'site/graphql';
import { useLinks } from '../../../core/components/linksContext';

type ILecturerProductsProps = {
    products: Pick<Product, 'id' | 'name'>[];
} & WithStyles<typeof styles>;

const LecturerProducts: React.FC<ILecturerProductsProps> = ({ classes, products }) => {
    const links = useLinks();

    if (isEmpty(products)) {
        return null;
    }

    return (
        <div className={classes.root}>
            <strong className={classes.title}>Читаю для Вас:</strong>
            <ul className={classes.bulletpoint}>
                {products.map(product => (
                    <li key={product.id}>
                        <links.productDetail.Link to={links.productDetail.url(product.id)}>
                            {product.name}
                        </links.productDetail.Link>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export const styles = (theme: ITheme) =>
    createStyles({
        root: {
            ...row(),
            alignItems: 'center',
        },
        title: {
            ...column(),

            [theme.breakpoints.down('xs')]: {
                fontSize: '0.89rem',
                textAlign: 'center',
            },
        },
        bulletpoint: {
            ...column(),
            listStyle: 'none',

            [theme.breakpoints.down('xs')]: {
                fontSize: '0.89rem',
            },
        },
    });

export default injectSheet(styles)(LecturerProducts);
