import { useLinks } from 'core/components/linksContext';
import { useMemo } from 'react';

export interface IService {
    icon: string;
    href?: string;
    title: string;
    description: string;
    linkTo?: boolean;
    isOpenNewTab?: boolean;
}

const useServices = () => {
    const links = useLinks();

    return useMemo<IService[]>(
        () => [
            {
                icon: require('../icons/portfolio.png'),
                href: links.ofz.url(),
                title: 'Собрать портфель из облигаций',
                description: 'Как получать купон каждый месяц',
            },
            {
                icon: require('../icons/dividend.png'),
                href: links.dividendTable.url(),
                title: 'Таблица дивидендных акций',
                description: 'Прошлые и ожидаемые дивиденды, див. доходность, когда закрывается дивидендный гэп',
            },
            {
                icon: require('../icons/weeks-52.png'),
                href: links.service52weeks.url(),
                title: '52 недели богатства',
                description: 'Планирование своих накоплений и инвестиций',
            },
            {
                icon: require('../icons/market-map.png'),
                href: links.serviceMarketMap.url(),
                title: 'Карта рынка',
                description: 'Наглядная карта рынка акций и ETF Московской биржи',
            },
            {
                icon: require('../icons/calc.png'),
                href: links.financialCalculators.url(),
                title: 'Калькулятор личных финансов',
                description:
                    'Рассчитать, сколько нужно откладывать, чтобы получать нужную сумму в будущем с учетом инфляции',
            },
            {
                icon: require('../icons/min-max.png'),
                href: links.highLow.url(),
                title: 'Минимумы и максимумы акций за год',
                description: 'Котировки акций на Мос. бирже относительно минимумов и максимумов за год',
            },
            {
                icon: require('../icons/broker.png'),
                href: links.brokers.url(),
                title: 'Выбрать брокера',
                description: 'Открыть счет на бирже',
            },
            {
                icon: require('../icons/memento.png'),
                href: 'https://www.mmntmori.com/',
                title: 'Сервис Memento',
                description: 'Сервис Memento для того, чтобы ваши счета не потерялись для наследников',
                linkTo: true,
                isOpenNewTab: true,
            },
            {
                icon: require('../icons/ariadne.png'),
                href: links.roboadviser.url(),
                title: 'КЦ.Робоэдвайзер',
                description: 'Собери портфель из акций и облигаций на российском рынке',
            },
            {
                icon: require('../icons/investment.png'),
                href: 'https://intelinvest.ru/?registration=true&ref=58977',
                title: 'Учет инвестиций',
                description:
                    'Добавляйте купленные акции и облигации, следите за портфелем и анализируйте доходность \n(14 дней бесплатно)',
                linkTo: true,
                isOpenNewTab: true,
            },
            {
                icon: require('../icons/options.png'),
                href: 'http://options.red-circule.com/',
                title: 'Калькулятор опционов',
                description: 'Бесплатное приложение для опционной торговли Online OptionWorkshop',
                linkTo: true,
            },
            {
                icon: require('../icons/calendar.png'),
                href: links.expcalendar.url(),
                title: 'Календарь экспираций',
                description: 'Календарь исполнения срочных контрактов Московской Биржи',
            },
            {
                icon: require('../icons/map.png'),
                href: links.heatmap.url(),
                title: 'Тепловая карта',
                description: 'Открытые позиции по опционам',
            },
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );
};

export default useServices;
