import { useAuth } from '../AuthProvider';

const useSubscriptions = () => {
    const { hasSubscription, myAccount } = useAuth();

    const subscriptionFreeCourses = myAccount && myAccount.subscriptionFreeCourses;
    const hasSubscriptionFreeCourses = !!subscriptionFreeCourses && !!subscriptionFreeCourses.until;

    const subscriptionCorporateBonds = myAccount && myAccount.subscriptionCorporateBonds;
    const hasSubscriptionCorporateBonds = !!subscriptionCorporateBonds && !!subscriptionCorporateBonds.until;

    return { hasSubscription, hasSubscriptionFreeCourses, hasSubscriptionCorporateBonds };
};

export default useSubscriptions;
