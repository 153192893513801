import c from 'classnames';
import Carousel from 'core/components/carousel';
import propsFromUrl from 'core/enhancers/propsFromUrl';
import { container } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { isMobile } from 'core/utils';
import React, { CSSProperties } from 'react';
import { compose } from 'recompose';
import { ISlideState } from '../courses/CourseList';
import { IFilterButton } from './MediumFilter';

type ISlide = IFilterButton & { description: string };

const DEFAULT_SLIDE = 1;

const slides: ISlide[] = [
    {
        title: 'УЧЕБНИКИ',
        slideState: 'TEXTBOOKS',
        description:
            'В данном разделе представлены текстовые версии некоторых наших курсов, а также учебники, пособия и другие материалы. Вы можете скачать их себе на любое устройство и читать в любом месте, в любое время',
    },
    {
        title: 'КУРСЫ',
        slideState: 'COURSES',
        description: 'В данном разделе представлены вебинары, семинары и группы онлайн-трейдинга',
    },
    {
        title: 'ВИДЕОКУРСЫ',
        slideState: 'VIDEO_COURSES',
        description: 'В данном разделе представлены видеокурсы и записи прошедших занятий',
    },
];

class SliderFilter extends React.Component<IInnerProps & IOutterProps> {
    handleSlideChange = (nextSlideIndex: number) => {
        const nextSlide = slides[nextSlideIndex];
        this.props.onSlideChanged(nextSlide.slideState);
    };

    render() {
        const { classes, slideState, showDescription = true, customContainerStyle } = this.props;
        const currentSlide = slideState ? slides.findIndex(s => s.slideState === slideState) : DEFAULT_SLIDE;
        const leftSlide = currentSlide - 1 < 0 ? slides.length - 1 : currentSlide - 1;
        const rightSlide = (currentSlide + 1) % slides.length;

        return (
            <>
                <div className={classes.bgColor}>
                    <div className={classes.container} style={customContainerStyle}>
                        <Carousel
                            slideIndex={currentSlide}
                            wrapAround
                            cellAlign="center"
                            slidesToShow={isMobile() ? 1 : 2.99}
                            speed={1}
                            dragging={false}
                            beforeSlide={this.handleSlideChange}
                        >
                            {slides.map((slide, i) =>
                                i === currentSlide ? (
                                    <h1 key={slide.title} className={c(classes.slide, classes.slideActive)}>
                                        {slide.title}
                                    </h1>
                                ) : (
                                    <div
                                        key={slide.title}
                                        onClick={() => this.handleSlideChange(i)}
                                        className={c(classes.slide, classes.slideSide, {
                                            [classes.slideLeft]: i === leftSlide,
                                            [classes.slideRight]: i === rightSlide,
                                        })}
                                    >
                                        {slide.title}
                                    </div>
                                ),
                            )}
                        </Carousel>
                    </div>
                </div>
                {showDescription && <h2 className={classes.description}>{slides[currentSlide].description}</h2>}
            </>
        );
    }
}

const styles = (theme: ITheme) =>
    createStyles({
        bgColor: {
            background: theme.colors.contrastBg,
        },
        container: {
            ...container(theme),
            display: 'flex',
            color: '#666666',
            height: 110,
            alignItems: 'center',

            [theme.breakpoints.down('xs')]: {
                marginLeft: 'calc((-100vw + 100%)/2)',
                marginRight: 'calc((-100vw + 100%)/2)',
            },
        },
        slide: {
            fontWeight: 700,
            fontSize: 1.4 + 'rem',
            margin: 0,
            textAlign: 'center',
        },
        slideActive: {
            color: '#fefefe',
        },
        slideLeft: {
            textAlign: 'start',
            paddingLeft: '2rem',
        },
        slideRight: {
            textAlign: 'end',
            paddingRight: '2rem',
        },
        slideSide: {
            cursor: 'pointer',

            '&:hover': {
                color: '#cccccc',
            },
        },
        description: {
            fontSize: 1.1 + 'rem',
            fontWeight: 600,
            marginTop: 1.4 + 'rem',
            marginBottom: 1.4 + 'rem',
            color: '#1e252f',
            ...container(theme),
        },
    });

export type IInnerProps = {
    slideState?: ISlideState;
} & WithStyles<typeof styles>;

export type IOutterProps = {
    onSlideChanged: (newSlide: ISlideState) => void;
    showDescription?: boolean;
    customContainerStyle?: CSSProperties;
};

export default compose<IInnerProps, IOutterProps>(
    propsFromUrl({ query: ['slideState'] }),
    injectSheet(styles),
)(SliderFilter);
