import React from 'react';
import { DefaultDropDown } from './dropDownHoc';

type IDropDownSelfState = {
    show: boolean;
    listPosition?: {
        top: any;
        left: any;
    };
};

type IDropDownSelfStateProps = Omit<
    React.ComponentProps<typeof DefaultDropDown>,
    'show' | 'listPosition' | 'close' | 'onClick'
>;

interface IDropDownSelfStateHocOptions {
    DropDown?: React.ComponentType<React.ComponentProps<typeof DefaultDropDown>>;
}

const dropDownSelfStateHoc = ({ DropDown = DefaultDropDown }: IDropDownSelfStateHocOptions) => {
    return class DropDownSelfState extends React.Component<IDropDownSelfStateProps, IDropDownSelfState> {
        state: IDropDownSelfState = {
            show: false,
        };

        componentDidMount() {
            document.addEventListener('keydown', this.onEscape);
        }

        componentWillUnmount() {
            document.removeEventListener('keydown', this.onEscape);
        }

        close = () => {
            this.setState({
                show: false,
            });
        };

        onEscape = e => {
            if (this.state.show && e.keyCode === 27) {
                this.close();
            }
        };

        toggle = ({ height }) => {
            this.setState({
                show: !this.state.show,
                listPosition: {
                    top: height,
                    left: 0,
                },
            });
        };

        render() {
            return <DropDown onClick={this.toggle} close={this.close} {...this.state} {...this.props} />;
        }
    };
};

export const DefaultDropDownSelfState = dropDownSelfStateHoc({});
export default dropDownSelfStateHoc;
