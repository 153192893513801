import { ifBrowser } from 'core/utils';

type IProductsFeedbackInLocalStorage = {
    [productId: number]: Date;
};

const LOCAL_STARAGE_KEY = 'rc-courses-feedback';

/**
 * Парсит хранилище
 * Архитектура хранилища: "{"productId":"beforeTime",...}"
 * Пример: "{"123":"2019-04-08T11:18:47.976Z","456":"2019-05-08T11:18:47.976Z"}"
 */
export const parseLocalStorage = (): IProductsFeedbackInLocalStorage => {
    if (!ifBrowser()) {
        return {};
    }

    const localStorageValue = window.localStorage.getItem(LOCAL_STARAGE_KEY);

    if (localStorageValue === null) {
        return {};
    }

    const obj = JSON.parse(localStorageValue);

    return Object.keys(obj).reduce((result, id) => {
        result[id] = new Date(obj[id]);
        return result;
    }, {});
};

/**
 * Удаляет прошедшие значения хранилища
 * Возвращает акутальные данные
 */
export const removeObsoleteLocalStorage = (): IProductsFeedbackInLocalStorage => {
    if (!ifBrowser()) {
        return {};
    }

    const values = parseLocalStorage();
    const onlyFutureValues = Object.keys(values).reduce<IProductsFeedbackInLocalStorage>((result, id) => {
        if (values[id] > new Date()) {
            result[id] = values[id];
        }

        return result;
    }, {});

    window.localStorage.setItem(LOCAL_STARAGE_KEY, JSON.stringify(onlyFutureValues));

    return onlyFutureValues;
};

/**
 * Добавляет значение в хранилище
 */
export const addToLocalStorage = (productId: number, date: Date): IProductsFeedbackInLocalStorage => {
    if (!ifBrowser()) {
        return {};
    }

    const values = parseLocalStorage();
    values[productId] = date;

    window.localStorage.setItem(LOCAL_STARAGE_KEY, JSON.stringify(values));

    return values;
};
