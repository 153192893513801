import withStateHandlers from 'recompose/withStateHandlers';
import { StateHandlerMap } from 'recompose';

type IShowAllState = {
    showAll: boolean;
};

interface IShowAllUpdaters extends StateHandlerMap<IShowAllState> {
    onShowAllClick(): Partial<IShowAllState>;
}

export type IShowAllEnhacerProps = {
    onShowAllClick: () => void;
} & IShowAllState;

export default withStateHandlers<IShowAllState, IShowAllUpdaters>(() => ({ showAll: false }), {
    onShowAllClick: () => () => ({ showAll: true }),
});
