import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { WithStyles, ITheme } from 'core/style/interfaces';
import React from 'react';
import ContactsIcon from '../../svg/cellphone.svg';
import EmailIcon from '../../svg/email-outline.svg';

interface IContactsPreviewProps {
    showHead: boolean;
    contrast: boolean; // Отвечает за цвет иконок
    email: string;
    phone: string;
}

const ContactsPreview: React.FC<IContactsPreviewProps & WithStyles<typeof styles>> = ({
    showHead,
    classes,
    email,
    phone,
    contrast,
}) => (
    <div className={classes.root}>
        {showHead && <h4 className={classes.title}>Остались вопросы?</h4>}
        <div className={classes.email}>
            <EmailIcon className={c(classes.icon, contrast === true && classes.icon_contrast)} />
            <a className={classes.eMailLink} target="blank" href={'mailto:' + email}>
                {email}
            </a>
        </div>
        <div className={classes.phone}>
            <ContactsIcon className={c(classes.icon, contrast === true && classes.icon_contrast)} />
            <a className={classes.phoneLink} href={'tel:' + phone.replace(/\s+/g, '')}>
                {phone}
            </a>
        </div>
    </div>
);

export const styles = (theme: ITheme) =>
    createStyles({
        root: {},
        title: {},
        email: {
            display: 'flex',
            marginBottom: 1 + 'rem',
        },
        phone: {
            display: 'flex',
        },
        icon: {
            marginRight: 0.5 + 'rem',
            width: 1.5 + 'rem',
            height: 1.5 + 'rem',
            fill: theme.colors.accentLight,
        },
        icon_contrast: {
            fill: 'white',
        },
        eMailLink: {
            textDecoration: 'none',
        },
        phoneLink: {
            textDecoration: 'none',
        },
    });

export default injectSheet(styles, 'ContactsPreview')(ContactsPreview);
