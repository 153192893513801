import c from 'classnames';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { formatNumberToDecimal } from 'core/utils';
import React from 'react';
import { HighLowSecurity } from 'site/graphql';

type IGraphProps = {
    security: Pick<HighLowSecurity, 'low' | 'current' | 'high' | 'position' | 'amplitude' | 'y1'>;
};

const Graph: React.FC<IGraphProps & WithStyles<typeof styles>> = ({
    classes,
    security: { low, current, high, position, amplitude, y1 },
}) => (
    <div className={classes.graphContainer}>
        <div className={classes.boundaryPrice}>{formatNumberToDecimal(low)}</div>
        <div className={classes.graph}>
            <div className={classes.graphLine} />
            <div style={{ left: `calc(${position}% - 7px)` }} className={c(classes.pointerContainer, classes.tooltip)}>
                <div
                    className={c(classes.currentPointer, {
                        [classes.currentPointerRed]: y1 < 0,
                        [classes.currentPointerGreen]: y1 > 0,
                    })}
                />
                <span className="tooltiptext">{`Годовая доходность: ${formatNumberToDecimal(y1)}%`}</span>
            </div>
            <div style={{ left: `calc(${position}% - 50px)` }} className={classes.currentPrice}>
                <b>{formatNumberToDecimal(current)}</b>
            </div>
            <div className={classes.amplitude}>Амплитуда: {formatNumberToDecimal(amplitude)}%</div>
        </div>
        <div className={classes.boundaryPrice}>{formatNumberToDecimal(high)}</div>
    </div>
);

const styles = (theme: ITheme) =>
    createStyles({
        graphContainer: {
            display: 'flex',
            flexGrow: 1,
            height: 54,

            [theme.breakpoints.down('xs')]: {
                marginTop: 1 + 'rem',
                width: 100 + '%',
            },
        },
        boundaryPrice: {
            width: 100,
            textAlign: 'center',
            fontSize: 0.8 + 'rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('xs')]: {
                fontWeight: 'bold',
            },
        },
        graph: {
            flex: 1,
            position: 'relative',
        },
        graphLine: {
            height: 1,
            backgroundColor: '#9D9D9D',
            position: 'absolute',
            top: 50 + '%',
            right: 0,
            left: 0,
        },
        currentPointer: {
            width: 0,
            height: 0,
            borderLeft: '7px solid transparent',
            borderRight: '7px solid transparent',
            borderTop: `12px solid ${theme.colors.black}`,
        },
        currentPointerRed: {
            borderTop: `12px solid ${theme.colors.accent}`,
        },
        currentPointerGreen: {
            borderTop: `12px solid ${theme.colors.green}`,
        },
        currentPrice: {
            fontSize: 0.8 + 'rem',
            position: 'absolute',
            width: 100,
            textAlign: 'center',
            top: -7,
        },
        amplitude: {
            fontSize: 0.6 + 'rem',
            fontWeight: 'bold',
            position: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            textAlign: 'center',

            [theme.breakpoints.down('xs')]: {
                fontSize: 0.5 + 'rem',
            },
        },
        pointerContainer: {
            position: 'absolute',
            top: 'calc(50% - 12px)',
            zIndex: 1,
        },
        tooltip: {
            display: 'inline-block',

            '& .tooltiptext': {
                visibility: 'hidden',
                width: '180px',
                backgroundColor: '#f9f9f9db',
                textAlign: 'center',
                borderRadius: 6,
                padding: '4px',
                position: 'absolute',
                zIndex: 2,
                top: '150%',
                left: '50%',
                marginLeft: '-90px',
                border: '1px solid #777',
                fontSize: 0.7 + 'rem',
                lineHeight: 1 + 'rem',
            },
            '& .tooltiptext::after': {
                content: '""',
                position: 'absolute',
                bottom: '100%',
                left: '50%',
                marginLeft: '-5px',
                borderWidth: 5,
                borderStyle: 'solid',
                borderColor: 'transparent transparent #777 transparent',
            },
            '&:hover .tooltiptext': {
                visibility: 'visible',
            },
        },
    });

export default injectSheet(styles)(Graph);
