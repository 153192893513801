// import css from 'core/style/CustomSwitch.module';
import React from 'react';
import { createStyles } from '@material-ui/core';
import injectSheet from 'core/style/injectSheet';
import { WithStyles } from 'core/style/interfaces';

type ICustomCheckboxProps = {
    className?: string;
    checked: boolean;
    label?: string;
    disabled?: boolean;
    onChange(checked: boolean): void;
} & WithStyles<typeof styles>;

const CustomSwitch: React.FC<ICustomCheckboxProps> = ({ classes, label, onChange, checked }) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const checked = e.target.checked;
        onChange(checked);
    };

    return (
        <div className={classes.container}>
            {label}
            <input type="checkbox" id="cbx" className={classes.checkbox} onChange={handleChange} checked={checked} />
            <label htmlFor="cbx" className={classes.containerChecked}>
                <span></span>
            </label>
        </div>
    );
};

const styles = () =>
    createStyles({
        containerChecked: {
            display: 'block',
            position: 'relative',
            width: 40 + 'px',
            height: 20 + 'px',
            cursor: 'pointer',
            userSelect: 'none',

            '&::before': {
                content: '""',
                position: 'relative',
                top: 3 + 'px',
                left: 3 + 'px',
                width: 34 + 'px',
                height: 14 + 'px',
                display: 'block',
                background: '#9a9999',
                'border-radius': 8 + 'px',
                transition: 'background 0.2s ease',
            },

            '& span': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: 20 + 'px',
                height: 20 + 'px',
                display: 'block',
                background: 'white',
                'border-radius': 10 + 'px',
                'box-shadow': '0 3px 8px rgba(154, 153, 153, 0.5)',
                transition: 'all 0.2s ease',
            },

            '& span::before': {
                content: '""',
                position: 'absolute',
                display: 'block',
                margin: -18 + 'px',
                width: 56 + 'px',
                height: 56 + 'px',
                background: 'rgba(204, 0, 0, 0.5)',
                'border-radius': 50 + '%',
                transform: 'scale(0)',
                opacity: 1,
                'pointer-events': 'none',
            },

            '#cbx:checked + &::before': {
                background: 'rgba(204, 0, 0, 0.6)',
            },

            '#cbx:checked + & span': {
                background: '#cc0000',
                transform: 'translateX(20px)',
                transition: 'all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease',
                'box-shadow': '0 3px 8px rgba(204, 0, 0, 0.2)',
            },

            '#cbx:checked + & span::before': {
                opacity: 0,
                transition: 'all 0.4s ease',
            },
        },

        container: {
            position: 'relative',
            'margin-left': 'auto',
            display: 'flex',
            'flex-direction': 'column',
            'align-items': 'center',
        },

        checkbox: {
            display: 'none',
        },
    });

export default injectSheet(styles)(CustomSwitch);
