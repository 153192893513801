import c from 'classnames';
import { fieldInput } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import ru from 'date-fns/locale/ru';
import React from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

type IDatePickerProps = {
    value: Date;
    onChange(value: Date): void;
    className?: string;
    wrapperClassName?: string;
    id?: string;
    disabled?: boolean;
} & WithStyles<typeof styles>;

const DatePicker: React.FC<IDatePickerProps> = ({
    classes,
    value,
    onChange,
    className,
    id,
    wrapperClassName,
    disabled,
}) => {
    return (
        <ReactDatePicker
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={20}
            dateFormat="dd.MM.yyyy"
            locale={ru}
            selected={value}
            onChange={onChange}
            id={id}
            className={c(className, classes.input)}
            minDate={new Date(2018, 0, 1)}
            maxDate={new Date(2040, 0, 1)}
            dayClassName={date =>
                date.getDate() === value.getDate() && date.getMonth() === value.getMonth() ? classes.day : undefined
            }
            wrapperClassName={wrapperClassName}
            disabled={disabled}
        />
    );
};

const styles = (theme: ITheme) =>
    createStyles({
        input: fieldInput,
        day: {
            backgroundColor: `${theme.colors.accentLight} !important`,
        },
    });

export default injectSheet(styles)(DatePicker);
