import { gql } from 'apollo-boost';
import { SettingsContext } from 'core/components/settingsContext';
import useFetchOnButton from 'core/hooks/useFetchOnButton';
import { useFiltersFromUrl } from 'core/hooks/useFiltersFromUrl';
import useQuery from 'core/hooks/useQuery';
import { useCallback, useContext, useMemo } from 'react';
import {
    LecturerListRequest,
    LecturerOrderEnum,
    ProductAdminVisibilityEnum,
    ProductListRequest,
    useGetLecturersQuery,
} from 'site/graphql';

const DEFAULT_TAKE = 3;
const DEFAULT_SKIP = 0;

gql`
    query GetLecturers($lecturersFilter: LecturerListRequest, $productsFilter: ProductListRequest) {
        lecturers(filter: $lecturersFilter) {
            count
            result {
                id
                education
                skill
                about
                account {
                    id
                    firstName
                    lastName
                    middleName
                    pictureId
                }
                products(filter: $productsFilter) {
                    id
                    name
                }
            }
        }
    }
`;

type IFiltersFromUrl = {
    search: string;
};

const useLecturersData = () => {
    const settings = useContext(SettingsContext);

    // Достаем фильтры из урла
    const { search } = useFiltersFromUrl<IFiltersFromUrl>();

    // Собираем фильтры
    const lecturersFilter = useMemo<LecturerListRequest>(
        () => ({
            take: DEFAULT_TAKE,
            skip: DEFAULT_SKIP,
            search,
            order: LecturerOrderEnum.LastName,
            showcaseContentProviderId: settings.contentProviderId,
        }),
        [search, settings],
    );
    const productsFilter = useMemo<ProductListRequest>(
        () => ({
            contentProviderId: settings.contentProviderId,
            visibility: ProductAdminVisibilityEnum.Public,
            full: true,
        }),
        [settings],
    );

    // Запрос на каждую смену урла
    const { data, loading, fetchMore } = useGetLecturersQuery({
        fetchPolicy: 'cache-and-network',
        variables: { lecturersFilter, productsFilter },
        notifyOnNetworkStatusChange: true,
    });

    // Преподаватели
    const lecturers = (data && data.lecturers.result) || [];
    const count = (data && data.lecturers.count) || 0;

    // Запрос со следующей страницей
    const fetchMoreQuery = useCallback(
        (take: number, skip: number) => {
            fetchMore({
                variables: { lecturersFilter: { ...lecturersFilter, take, skip }, productsFilter },
                updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    return Object.assign({}, prev, {
                        lecturers: {
                            ...prev.lecturers,
                            count: fetchMoreResult.lecturers.count,
                            result: [...prev.lecturers.result, ...fetchMoreResult.lecturers.result],
                        },
                    });
                },
            });
        },
        [fetchMore, lecturersFilter, productsFilter],
    );

    // Смена фильтров в урле
    const { changeQuery } = useQuery();
    const fetch = useCallback(
        (newFilter: object) => {
            changeQuery({ ...lecturersFilter, ...newFilter });
        },
        [changeQuery, lecturersFilter],
    );

    // Хук для кнопки "Загрузить еще"
    const [isAllItemsDownloaded, loadMore] = useFetchOnButton({
        count,
        defaultSkip: DEFAULT_SKIP,
        defaultTake: DEFAULT_TAKE,
        fetch: fetchMoreQuery,
        loading,
        values: lecturers,
    });

    return [isAllItemsDownloaded, loadMore, fetch, lecturers, loading, search] as const;
};

export default useLecturersData;
