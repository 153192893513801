import React from 'react';
import Animate from 'react-move/Animate';
import { PagingDots, PreviousButton, NextButton } from './controls';
import ScrollTransition from './transitions/scroll-transition';
import { ifBrowser, isMobile } from 'core/utils';

const addEvent = (elem, type, eventHandle) => {
    if (elem === null || typeof elem === 'undefined') {
        return;
    }
    if (elem.addEventListener) {
        elem.addEventListener(type, eventHandle, false);
    } else if (elem.attachEvent) {
        elem.attachEvent(`on${type}`, eventHandle);
    } else {
        elem[`on${type}`] = eventHandle;
    }
};

const removeEvent = (elem, type, eventHandle) => {
    if (elem === null || typeof elem === 'undefined') {
        return;
    }
    if (elem.removeEventListener) {
        elem.removeEventListener(type, eventHandle, false);
    } else if (elem.detachEvent) {
        elem.detachEvent(`on${type}`, eventHandle);
    } else {
        elem[`on${type}`] = null;
    }
};

export type IControlProps = {
    currentSlide: ICarouselState['currentSlide'];
    slideCount: ICarouselState['slideCount'];
    frameWidth: ICarouselState['frameWidth'];
    slideWidth: ICarouselState['slideWidth'];
    slidesToScroll: ICarouselState['slidesToScroll'];
    cellSpacing: ICarouselProps['cellSpacing'];
    slidesToShow: ICarouselState['slidesToShow'];
    wrapAround: ICarouselProps['wrapAround'];
    nextSlide: () => void;
    previousSlide: () => void;
    goToSlide: (index) => void;
};

type ICarouselProps = {
    afterSlide?: (currentSlide: number) => void;
    autoplay?: boolean;
    autoplayInterval?: number;
    beforeSlide?: (nextSlide: number) => void;
    cellAlign?: 'left' | 'center' | 'right';
    cellSpacing?: number;
    dragging?: boolean;
    frameOverflow?: string;
    framePadding?: string;
    heightMode?: 'first' | 'current' | 'max';
    initialSlideHeight?: number;
    initialSlideWidth?: number;
    onResize?: () => void;
    renderTopLeftControls?: (props: IControlProps) => React.ReactElement<any>;
    renderTopCenterControls?: (props: IControlProps) => React.ReactElement<any>;
    renderTopRightControls?: (props: IControlProps) => React.ReactElement<any>;
    renderCenterLeftControls?: (props: IControlProps) => React.ReactElement<any>;
    renderCenterCenterControls?: (props: IControlProps) => React.ReactElement<any>;
    renderCenterRightControls?: (props: IControlProps) => React.ReactElement<any>;
    renderBottomLeftControls?: (props: IControlProps) => React.ReactElement<any>;
    renderBottomCenterControls?: (props: IControlProps) => React.ReactElement<any>;
    renderBottomRightControls?: (props: IControlProps) => React.ReactElement<any>;
    decoratorStyleCenterLeftControls?: React.CSSProperties;
    decoratorStyleCenterRightControls?: React.CSSProperties;
    slideIndex?: number;
    slidesToScroll?: number | 'auto';
    slidesToShow?: number;
    slideWidth?: string | number;
    speed?: number;
    swiping?: boolean;
    vertical?: boolean;
    width?: string;
    wrapAround?: boolean;
    className?: string;
    style?: object;
    enableHandleClick?: boolean;
    classNameLi?: string;
};

type ICarouselState = {
    currentSlide: number;
    dragging: boolean;
    frameWidth: number | string;
    left: number;
    slideCount: number;
    slidesToScroll: number;
    slidesToShow: number;
    slideWidth: number;
    top: number;
    cellAlign: string;
    isWrappingAround: boolean;
    wrapToIndex: any;
    resetWrapAroundPosition: boolean;
    slideHeight: number;
};

export default class Carousel extends React.Component<ICarouselProps, ICarouselState> {
    static defaultProps = {
        afterSlide: () => null,
        autoplay: false,
        autoplayInterval: 3000,
        beforeSlide: () => null,
        cellAlign: 'left',
        cellSpacing: 0,
        dragging: true,
        framePadding: '0px',
        frameOverflow: 'hidden',
        heightMode: 'max',
        onResize: () => null,
        slideIndex: 0,
        slidesToScroll: 1,
        slidesToShow: 1,
        style: {},
        renderCenterLeftControls: props => <PreviousButton {...props} />,
        renderCenterRightControls: props => <NextButton {...props} />,
        renderBottomCenterControls: props => <PagingDots {...props} />,
        slideWidth: 1,
        speed: 500,
        swiping: true,
        vertical: false,
        width: '100%',
        wrapAround: false,
        enableHandleClick: true,
    };

    displayName: any;
    clickSafe: any;
    touchObject: any;
    controlsMap: any;
    mounted: any;
    frame: any;
    autoplayPaused: any;
    autoplayID: any;

    constructor(props, context) {
        super(props, context);

        this.displayName = 'Carousel';
        this.clickSafe = false;
        this.touchObject = {};
        this.controlsMap = [
            { funcName: 'renderTopLeftControls', key: 'TopLeft' },
            { funcName: 'renderTopCenterControls', key: 'TopCenter' },
            { funcName: 'renderTopRightControls', key: 'TopRight' },
            { funcName: 'renderCenterLeftControls', key: 'CenterLeft' },
            { funcName: 'renderCenterCenterControls', key: 'CenterCenter' },
            { funcName: 'renderCenterRightControls', key: 'CenterRight' },
            { funcName: 'renderBottomLeftControls', key: 'BottomLeft' },
            { funcName: 'renderBottomCenterControls', key: 'BottomCenter' },
            { funcName: 'renderBottomRightControls', key: 'BottomRight' },
        ];

        const { slidesToScroll, slidesToShow, cellAlign } = this.getPropsByTransitionMode(this.props, [
            'slidesToScroll',
            'slidesToShow',
            'cellAlign',
        ]);

        this.state = {
            currentSlide: this.props.slideIndex,
            dragging: false,
            frameWidth: 0,
            left: 0,
            slideCount: 0,
            slidesToScroll,
            slidesToShow,
            slideWidth: 0,
            top: 0,
            cellAlign,
            isWrappingAround: false,
            wrapToIndex: null,
            resetWrapAroundPosition: false,
            slideHeight: undefined,
        };

        this.getTouchEvents = this.getTouchEvents.bind(this);
        this.getMouseEvents = this.getMouseEvents.bind(this);
        this.handleMouseOver = this.handleMouseOver.bind(this);
        this.handleMouseOut = this.handleMouseOut.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleSwipe = this.handleSwipe.bind(this);
        this.swipeDirection = this.swipeDirection.bind(this);
        this.autoplayIterator = this.autoplayIterator.bind(this);
        this.startAutoplay = this.startAutoplay.bind(this);
        this.stopAutoplay = this.stopAutoplay.bind(this);
        this.resetAutoplay = this.resetAutoplay.bind(this);
        this.goToSlide = this.goToSlide.bind(this);
        this.nextSlide = this.nextSlide.bind(this);
        this.previousSlide = this.previousSlide.bind(this);
        this.getTargetLeft = this.getTargetLeft.bind(this);
        this.onResize = this.onResize.bind(this);
        this.onReadyStateChange = this.onReadyStateChange.bind(this);
        this.onVisibilityChange = this.onVisibilityChange.bind(this);
        this.setInitialDimensions = this.setInitialDimensions.bind(this);
        this.setDimensions = this.setDimensions.bind(this);
        this.setLeft = this.setLeft.bind(this);
        this.getFrameStyles = this.getFrameStyles.bind(this);
        this.getSliderStyles = this.getSliderStyles.bind(this);
        this.getOffsetDeltas = this.getOffsetDeltas.bind(this);
        this.getChildNodes = this.getChildNodes.bind(this);
        this.getSlideHeight = this.getSlideHeight.bind(this);
        this.findMaxHeightSlide = this.findMaxHeightSlide.bind(this);
    }

    UNSAFE_componentWillMount() {
        this.setInitialDimensions();
    }

    componentDidMount() {
        // see https://github.com/facebook/react/issues/3417#issuecomment-121649937
        this.mounted = true;
        this.setDimensions();
        this.bindEvents();
        if (this.props.autoplay) {
            this.startAutoplay();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const slideCount = this.getValidChildren(nextProps.children).length;
        const slideCountChanged = slideCount !== this.state.slideCount;

        this.setState({ slideCount });
        if (slideCount <= this.state.currentSlide) {
            this.goToSlide(Math.max(slideCount - 1, 0));
        }

        const updateDimensions =
            slideCountChanged ||
            ((curr, next, keys) => {
                let shouldUpdate = false;

                for (let i = 0; i < keys.length; i++) {
                    if (curr[keys[i]] !== next[keys[i]]) {
                        shouldUpdate = true;
                        break;
                    }
                }

                return shouldUpdate;
            })(this.props, nextProps, [
                'cellSpacing',
                'vertical',
                'slideWidth',
                'slideHeight',
                'heightMode',
                'slidesToScroll',
                'slidesToShow',
                'cellAlign',
            ]);

        if (updateDimensions) {
            this.setDimensions(nextProps);
        }

        if (
            this.props.slideIndex !== nextProps.slideIndex &&
            nextProps.slideIndex !== this.state.currentSlide &&
            !this.state.isWrappingAround
        ) {
            this.goToSlide(nextProps.slideIndex, false);
        }

        if (this.props.autoplay !== nextProps.autoplay) {
            if (nextProps.autoplay) {
                this.startAutoplay();
            } else {
                this.stopAutoplay();
            }
        }
    }

    componentWillUnmount() {
        this.unbindEvents();
        this.stopAutoplay();
        // see https://github.com/facebook/react/issues/3417#issuecomment-121649937
        this.mounted = false;
    }

    getPropsByTransitionMode(props, keys): any {
        const updatedDefaults = {};

        keys.forEach(key => {
            updatedDefaults[key] = props[key];
        });

        return updatedDefaults;
    }

    getTouchEvents() {
        if (this.props.swiping === false) {
            return null;
        }

        return {
            onTouchStart: e => {
                this.touchObject = {
                    startX: e.touches[0].pageX,
                    startY: e.touches[0].pageY,
                };
                this.handleMouseOver();
            },
            onTouchMove: e => {
                const direction = this.swipeDirection(
                    this.touchObject.startX,
                    e.touches[0].pageX,
                    this.touchObject.startY,
                    e.touches[0].pageY,
                );

                if (direction !== 0) {
                    e.preventDefault();
                }

                const length = this.props.vertical
                    ? Math.round(Math.sqrt(Math.pow(e.touches[0].pageY - this.touchObject.startY, 2)))
                    : Math.round(Math.sqrt(Math.pow(e.touches[0].pageX - this.touchObject.startX, 2)));

                this.touchObject = {
                    startX: this.touchObject.startX,
                    startY: this.touchObject.startY,
                    endX: e.touches[0].pageX,
                    endY: e.touches[0].pageY,
                    length,
                    direction,
                };

                this.setState({
                    left: this.props.vertical
                        ? 0
                        : this.getTargetLeft(this.touchObject.length * this.touchObject.direction),
                    top: this.props.vertical
                        ? this.getTargetLeft(this.touchObject.length * this.touchObject.direction)
                        : 0,
                });
            },
            onTouchEnd: () => {
                this.handleSwipe();
                this.handleMouseOut();
            },
            onTouchCancel: () => {
                this.handleSwipe();
            },
        };
    }

    getMouseEvents() {
        if (this.props.dragging === false) {
            return null;
        }

        return {
            onMouseOver: () => this.handleMouseOver(),

            onMouseOut: () => this.handleMouseOut(),

            onMouseDown: e => {
                this.touchObject = {
                    startX: e.clientX,
                    startY: e.clientY,
                };

                this.setState({
                    dragging: true,
                });
            },
            onMouseMove: e => {
                if (!this.state.dragging) {
                    return;
                }

                const direction = this.swipeDirection(
                    this.touchObject.startX,
                    e.clientX,
                    this.touchObject.startY,
                    e.clientY,
                );

                if (direction !== 0) {
                    e.preventDefault();
                }

                const length = this.props.vertical
                    ? Math.round(Math.sqrt(Math.pow(e.clientY - this.touchObject.startY, 2)))
                    : Math.round(Math.sqrt(Math.pow(e.clientX - this.touchObject.startX, 2)));

                this.touchObject = {
                    startX: this.touchObject.startX,
                    startY: this.touchObject.startY,
                    endX: e.clientX,
                    endY: e.clientY,
                    length,
                    direction,
                };

                this.setState({
                    left: this.props.vertical
                        ? 0
                        : this.getTargetLeft(this.touchObject.length * this.touchObject.direction),
                    top: this.props.vertical
                        ? this.getTargetLeft(this.touchObject.length * this.touchObject.direction)
                        : 0,
                });
            },
            onMouseUp: () => {
                if (!this.state.dragging) {
                    return;
                }

                this.handleSwipe();
            },
            onMouseLeave: () => {
                if (!this.state.dragging) {
                    return;
                }

                this.handleSwipe();
            },
        };
    }

    pauseAutoplay() {
        if (this.props.autoplay) {
            this.autoplayPaused = true;
            this.stopAutoplay();
        }
    }

    unpauseAutoplay() {
        if (this.props.autoplay && this.autoplayPaused) {
            this.startAutoplay();
            this.autoplayPaused = null;
        }
    }

    handleMouseOver() {
        this.pauseAutoplay();
    }

    handleMouseOut() {
        this.unpauseAutoplay();
    }

    handleClick(event) {
        if (this.clickSafe === true && this.props.enableHandleClick) {
            event.preventDefault();
            event.stopPropagation();

            if (event.nativeEvent) {
                event.nativeEvent.stopPropagation();
            }
        }
    }

    handleSwipe() {
        if (typeof this.touchObject.length !== 'undefined' && this.touchObject.length > 44) {
            this.clickSafe = true;
        } else {
            this.clickSafe = false;
        }

        let slidesToShow = this.state.slidesToShow;
        if (this.props.slidesToScroll === 'auto') {
            slidesToShow = this.state.slidesToScroll;
        }

        if (this.touchObject.length > this.state.slideWidth / slidesToShow / 5) {
            if (this.touchObject.direction === 1) {
                if (!(this.state.currentSlide >= this.state.slideCount - slidesToShow && !this.props.wrapAround)) {
                    this.nextSlide();
                }
            } else if (this.touchObject.direction === -1) {
                if (!(this.state.currentSlide <= 0 && !this.props.wrapAround)) {
                    this.previousSlide();
                }
            }
        } else {
            this.goToSlide(this.state.currentSlide);
        }

        this.touchObject = {};

        this.setState({
            dragging: false,
        });
    }

    swipeDirection(x1, x2, y1, y2) {
        const xDist = x1 - x2;
        const yDist = y1 - y2;
        const r = Math.atan2(yDist, xDist);
        let swipeAngle = Math.round((r * 180) / Math.PI);

        if (swipeAngle < 0) {
            swipeAngle = 360 - Math.abs(swipeAngle);
        }
        if (swipeAngle <= 45 && swipeAngle >= 0) {
            return 1;
        }
        if (swipeAngle <= 360 && swipeAngle >= 315) {
            return 1;
        }
        if (swipeAngle >= 135 && swipeAngle <= 225) {
            return -1;
        }
        if (this.props.vertical === true) {
            if (swipeAngle >= 35 && swipeAngle <= 135) {
                return 1;
            } else {
                return -1;
            }
        }
        return 0;
    }

    autoplayIterator() {
        if (this.props.wrapAround) {
            this.nextSlide();
            return;
        }
        if (this.state.currentSlide !== this.state.slideCount - this.state.slidesToShow) {
            this.nextSlide();
        } else {
            this.stopAutoplay();
        }
    }

    startAutoplay() {
        this.autoplayID = setInterval(this.autoplayIterator, this.props.autoplayInterval);
    }

    resetAutoplay() {
        if (this.props.autoplay && !this.autoplayPaused) {
            this.stopAutoplay();
            this.startAutoplay();
        }
    }

    stopAutoplay() {
        if (this.autoplayID) {
            clearInterval(this.autoplayID);
        }
    }

    // Action Methods
    goToSlide(index, invokeCallbacks = true) {
        if (index >= this.state.slideCount || index < 0) {
            if (!this.props.wrapAround) {
                return;
            }
            if (index >= this.state.slideCount) {
                invokeCallbacks && this.props.beforeSlide(0);
                this.setState(
                    prevState => ({
                        left: this.props.vertical
                            ? 0
                            : this.getTargetLeft(this.state.slideWidth, prevState.currentSlide),
                        top: this.props.vertical
                            ? this.getTargetLeft(this.state.slideWidth, prevState.currentSlide)
                            : 0,
                        currentSlide: 0,
                        isWrappingAround: true,
                        wrapToIndex: index,
                    }),
                    () =>
                        setTimeout(() => {
                            this.setState({ isWrappingAround: false, resetWrapAroundPosition: true }, () => {
                                this.setState({ resetWrapAroundPosition: false });

                                invokeCallbacks && this.props.afterSlide(0);
                                this.resetAutoplay();
                            });
                        }, this.props.speed),
                );
                return;
            } else {
                const endSlide = this.state.slideCount - this.state.slidesToScroll;

                invokeCallbacks && this.props.beforeSlide(endSlide);
                this.setState(
                    prevState => ({
                        left: this.props.vertical ? 0 : this.getTargetLeft(0, prevState.currentSlide),
                        top: this.props.vertical ? this.getTargetLeft(0, prevState.currentSlide) : 0,
                        currentSlide: endSlide,
                        isWrappingAround: true,
                        wrapToIndex: index,
                    }),
                    () =>
                        setTimeout(() => {
                            this.setState({ isWrappingAround: false, resetWrapAroundPosition: true }, () => {
                                this.setState({ resetWrapAroundPosition: false });

                                invokeCallbacks && this.props.afterSlide(endSlide);
                                this.resetAutoplay();
                            });
                        }, this.props.speed),
                );
                return;
            }
        }

        invokeCallbacks && this.props.beforeSlide(index);
        const prevSlide = this.state.currentSlide;

        this.setState(
            {
                currentSlide: index,
            },
            () => {
                if (index !== prevSlide) {
                    invokeCallbacks && this.props.afterSlide(index);
                }

                this.resetAutoplay();

                if (this.props.heightMode === 'current') {
                    this.setDimensions();
                }
            },
        );
    }

    nextSlide() {
        const childrenCount = this.state.slideCount;
        let slidesToShow = this.state.slidesToShow;

        if (this.props.slidesToScroll === 'auto') {
            slidesToShow = this.state.slidesToScroll;
        }

        if (
            this.state.currentSlide >= childrenCount - slidesToShow &&
            !this.props.wrapAround &&
            this.props.cellAlign === 'left'
        ) {
            return;
        }

        if (this.props.wrapAround) {
            this.goToSlide(this.state.currentSlide + this.state.slidesToScroll);
        } else {
            if (this.props.slideWidth !== 1) {
                this.goToSlide(this.state.currentSlide + this.state.slidesToScroll);
                return;
            }
            const offset = this.state.currentSlide + this.state.slidesToScroll;
            const nextSlideIndex =
                this.props.cellAlign !== 'left' ? offset : Math.min(offset, childrenCount - slidesToShow);
            this.goToSlide(nextSlideIndex);
        }
    }

    previousSlide() {
        if (this.state.currentSlide <= 0 && !this.props.wrapAround) {
            return;
        }

        if (this.props.wrapAround) {
            this.goToSlide(this.state.currentSlide - this.state.slidesToScroll);
        } else {
            this.goToSlide(Math.max(0, this.state.currentSlide - this.state.slidesToScroll));
        }
    }

    // Animation
    getTargetLeft(touchOffset = 0, target = this.state.currentSlide): any {
        let offset;
        const { frameWidth, slideWidth, currentSlide, slideCount, slidesToScroll, cellAlign } = this.state;

        switch (cellAlign) {
            case 'center': {
                offset = ((frameWidth as number) - slideWidth) / 2;
                offset -= this.props.cellSpacing * target;
                break;
            }
            case 'right': {
                offset = (frameWidth as number) - slideWidth;
                offset -= this.props.cellSpacing * target;
                break;
            }
            case 'left':
            default: {
                offset = 0;
                offset -= this.props.cellSpacing * target;
                break;
            }
        }

        let left = slideWidth * target;

        const lastSlide = currentSlide > 0 && target + slidesToScroll >= slideCount;

        if (
            lastSlide &&
            this.props.slideWidth !== 1 &&
            !this.props.wrapAround &&
            this.props.slidesToScroll === 'auto'
        ) {
            left = slideWidth * slideCount - (frameWidth as number);
            offset = 0;
            offset -= this.props.cellSpacing * (slideCount - 1);
        }

        offset -= touchOffset || 0;

        return (left - offset) * -1;
    }

    // Bootstrapping
    bindEvents() {
        if (ifBrowser()) {
            addEvent(window, 'resize', this.onResize);
            addEvent(document, 'readystatechange', this.onReadyStateChange);
            addEvent(document, 'visibilitychange', this.onVisibilityChange);
        }
    }

    onResize() {
        this.setDimensions(null, this.props.onResize);
    }

    onReadyStateChange() {
        this.setDimensions();
    }

    onVisibilityChange() {
        if (document.hidden) {
            this.pauseAutoplay();
        } else {
            this.unpauseAutoplay();
        }
    }

    unbindEvents() {
        if (ifBrowser()) {
            removeEvent(window, 'resize', this.onResize);
            removeEvent(document, 'readystatechange', this.onReadyStateChange);
            removeEvent(document, 'visibilitychange', this.onVisibilityChange);
        }
    }

    setInitialDimensions() {
        const slideWidth = this.props.vertical ? this.props.initialSlideHeight || 0 : this.props.initialSlideWidth || 0;
        const slideHeight = this.props.vertical
            ? (this.props.initialSlideHeight || 0) * this.state.slidesToShow
            : this.props.initialSlideHeight || 0;

        const frameHeight = slideHeight + this.props.cellSpacing * (this.state.slidesToShow - 1);

        this.setState(
            {
                slideHeight,
                frameWidth: this.props.vertical ? frameHeight : '100%',
                slideCount: this.getValidChildren(this.props.children).length,
                slideWidth,
            },
            () => {
                this.setLeft();
            },
        );
    }

    findMaxHeightSlide(slides) {
        let maxHeight = 0;

        for (let i = 0; i < slides.length; i++) {
            if (slides[i].offsetHeight > maxHeight) {
                maxHeight = slides[i].offsetHeight;
            }
        }
        return maxHeight;
    }

    getSlideHeight(props, childNodes = []) {
        const { heightMode, vertical } = props;
        const firstSlide = childNodes[0];
        if (firstSlide && heightMode === 'first') {
            return vertical ? firstSlide.offsetHeight * this.state.slidesToShow : firstSlide.offsetHeight;
        }
        if (heightMode === 'max') {
            return this.findMaxHeightSlide(childNodes);
        }
        if (props.heightMode === 'current') {
            return childNodes[this.state.currentSlide].offsetHeight;
        }

        return 100;
    }

    setDimensions(
        props?,
        stateCb = () => {
            /* do nothing */
        },
    ): any {
        props = props || this.props;
        const { slidesToShow, cellAlign } = this.getPropsByTransitionMode(props, ['slidesToShow', 'cellAlign']);

        const frame = this.frame;
        const childNodes = this.getChildNodes();
        const slideHeight = this.getSlideHeight(props, childNodes);

        let slideWidth;

        if (typeof props.slideWidth !== 'number') {
            slideWidth = parseInt(props.slideWidth);
        } else if (props.vertical) {
            slideWidth = (slideHeight / slidesToShow) * props.slideWidth;
        } else {
            slideWidth = (frame.offsetWidth / slidesToShow) * props.slideWidth;
        }

        if (!props.vertical) {
            slideWidth -= props.cellSpacing * ((100 - 100 / slidesToShow) / 100);
        }

        const frameHeight = slideHeight + props.cellSpacing * (slidesToShow - 1);
        const frameWidth = props.vertical ? frameHeight : frame.offsetWidth;

        let { slidesToScroll } = this.getPropsByTransitionMode(props, ['slidesToScroll']);

        if (slidesToScroll === 'auto') {
            slidesToScroll = Math.floor(frameWidth / (slideWidth + props.cellSpacing));
        }

        this.setState(
            {
                slideHeight,
                frameWidth,
                slideWidth,
                slidesToScroll,
                slidesToShow,
                cellAlign,
                left: props.vertical ? 0 : this.getTargetLeft(),
                top: props.vertical ? this.getTargetLeft() : 0,
            },
            () => {
                stateCb();
                this.setLeft();
            },
        );
    }

    getValidChildren(children) {
        // .toArray automatically removes invalid React children
        return React.Children.toArray(children);
    }

    getChildNodes() {
        return this.frame.childNodes[0].childNodes;
    }

    setLeft() {
        const newLeft = this.props.vertical ? 0 : this.getTargetLeft();
        const newTop = this.props.vertical ? this.getTargetLeft() : 0;

        if (newLeft !== this.state.left || newTop !== this.state.top) {
            this.setState({
                left: newLeft,
                top: newTop,
            });
        }
    }

    // Styles
    getFrameStyles() {
        return {
            position: 'relative',
            display: 'block',
            overflow: this.props.frameOverflow,
            height: this.props.vertical ? this.state.frameWidth || 'initial' : 'auto',
            margin: this.props.framePadding,
            padding: 0,

            paddingBottom: 10,

            transform: 'translate3d(0, 0, 0)',
            WebkitTransform: 'translate3d(0, 0, 0)',
            msTransform: 'translate(0, 0)',
            boxSizing: 'border-box',
            MozBoxSizing: 'border-box',
            touchAction: `pinch-zoom ${this.props.vertical ? 'pan-x' : 'pan-y'}`,
        };
    }

    getSliderStyles(): React.CSSProperties {
        return {
            position: 'relative',
            display: 'block',
            width: this.props.width,
            height: 'auto',
            boxSizing: 'border-box',
            MozBoxSizing: 'border-box',
            visibility: this.state.slideWidth ? 'inherit' : 'hidden',
        };
    }

    getDecoratorStyles(position): React.CSSProperties {
        switch (position) {
            case 'TopLeft': {
                return {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                };
            }
            case 'TopCenter': {
                return {
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    WebkitTransform: 'translateX(-50%)',
                    msTransform: 'translateX(-50%)',
                };
            }
            case 'TopRight': {
                return {
                    position: 'absolute',
                    top: 0,
                    right: 0,
                };
            }
            case 'CenterLeft': {
                return {
                    position: 'absolute',
                    top: '50%',
                    left: isMobile() ? '10%' : '35%',
                    transform: 'translateY(-50%)',
                    WebkitTransform: 'translateY(-50%)',
                    msTransform: 'translateY(-50%)',
                    ...this.props.decoratorStyleCenterLeftControls,
                };
            }
            case 'CenterCenter': {
                return {
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)',
                    WebkitTransform: 'translate(-50%, -50%)',
                    msTransform: 'translate(-50%, -50%)',
                };
            }
            case 'CenterRight': {
                return {
                    position: 'absolute',
                    top: '50%',
                    right: isMobile() ? '10%' : '35%',
                    transform: 'translateY(-50%)',
                    WebkitTransform: 'translateY(-50%)',
                    msTransform: 'translateY(-50%)',
                    ...this.props.decoratorStyleCenterRightControls,
                };
            }
            case 'BottomLeft': {
                return {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                };
            }
            case 'BottomCenter': {
                return {
                    position: 'absolute',
                    bottom: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    WebkitTransform: 'translateX(-50%)',
                    msTransform: 'translateX(-50%)',
                };
            }
            case 'BottomRight': {
                return {
                    position: 'absolute',
                    bottom: 0,
                    right: 0,
                };
            }
            default: {
                return {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                };
            }
        }
    }

    getOffsetDeltas() {
        let offset = 0;

        if (this.state.isWrappingAround) {
            offset = this.getTargetLeft(null, this.state.wrapToIndex);
        } else {
            offset = this.getTargetLeft(this.touchObject.length * this.touchObject.direction);
        }

        return {
            tx: [this.props.vertical ? 0 : offset],
            ty: [this.props.vertical ? offset : 0],
        };
    }

    getTransitionProps(): any {
        return {
            slideWidth: this.state.slideWidth,
            slideHeight: this.state.slideHeight,
            slideCount: this.state.slideCount,
            currentSlide: this.state.currentSlide,
            isWrappingAround: this.state.isWrappingAround,
            top: this.state.top,
            left: this.state.left,
            cellSpacing: this.props.cellSpacing,
            vertical: this.props.vertical,
            dragging: this.props.dragging,
            wrapAround: this.props.wrapAround,
            slidesToShow: this.state.slidesToShow,
        };
    }

    renderControls() {
        return this.controlsMap.map(({ funcName, key }) => {
            const func = this.props[funcName];
            return (
                func &&
                typeof func === 'function' && (
                    <div
                        className={`slider-control-${key.toLowerCase()}`}
                        style={this.getDecoratorStyles(key)}
                        key={key}
                    >
                        {func({
                            currentSlide: this.state.currentSlide,
                            slideCount: this.state.slideCount,
                            frameWidth: this.state.frameWidth,
                            slideWidth: this.state.slideWidth,
                            slidesToScroll: this.state.slidesToScroll,
                            cellSpacing: this.props.cellSpacing,
                            slidesToShow: this.state.slidesToShow,
                            wrapAround: this.props.wrapAround,
                            nextSlide: () => this.nextSlide(),
                            previousSlide: () => this.previousSlide(),
                            goToSlide: index => this.goToSlide(index),
                        })}
                    </div>
                )
            );
        });
    }

    render() {
        const duration = this.state.dragging || this.state.resetWrapAroundPosition ? 0 : this.props.speed;

        const frameStyles = this.getFrameStyles();
        const touchEvents = this.getTouchEvents();
        const mouseEvents = this.getMouseEvents();
        const validChildren = this.getValidChildren(this.props.children);

        return (
            <div
                className={['slider', this.props.className || ''].join(' ')}
                style={Object.assign({}, this.getSliderStyles(), this.props.style)}
            >
                <Animate
                    show
                    start={{ tx: 0, ty: 0 }}
                    update={Object.assign({}, this.getOffsetDeltas(), {
                        timing: {
                            duration,
                            ease: t => +t,
                        },
                        events: { end: this.setLeft },
                    })}
                >
                    {({ tx, ty }) => (
                        <div
                            className="slider-frame"
                            ref={frame => (this.frame = frame)}
                            style={frameStyles as any}
                            {...touchEvents}
                            {...mouseEvents}
                            onClick={this.handleClick}
                        >
                            <ScrollTransition
                                {...this.getTransitionProps()}
                                deltaX={tx}
                                deltaY={ty}
                                classNameLi={this.props.classNameLi}
                            >
                                {validChildren}
                            </ScrollTransition>
                        </div>
                    )}
                </Animate>

                {this.renderControls()}
            </div>
        );
    }
}
