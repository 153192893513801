import c from 'classnames';
import { getPictureUrl } from 'core/api/pictures';
import { LinksConsumer } from 'core/components/linksContext';
import { column, ieFix, row } from 'core/style';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { formatToLocalDayMonthYear } from 'core/utils';
import { isFilledArray } from 'core/utils/fp';
import React from 'react';
import { Link } from 'react-router-dom';
import { Article } from 'site/graphql';
import LecturerIcon from '../../svg/lecturer.svg';

const styles = (theme: ITheme) =>
    createStyles({
        itemBig: {
            minWidth: 40 + '%',
            maxWidth: 47.5 + '%',
            flexShrink: 0,
            flexGrow: 1,
            flexBasis: 40 + '%',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            boxSizing: 'border-box',
            paddingLeft: 15,
            paddingRight: 15,
            height: 305,
            fontFamily: 'Arial, sans-serif',
            display: 'flex',
            position: 'relative',
            margin: 'auto auto 1.5rem auto',

            '& $title': {
                color: theme.colors.lightBg,
                zIndex: 1,
            },
            '& $titleLink': {
                zIndex: 1,
            },
            '& $tag': {
                color: theme.colors.lightBg,
            },
            '& $tagsPanel': {
                zIndex: 1,
            },
            '& $date': {
                color: theme.colors.lightBg,
                zIndex: 1,
            },
            '& $name': {
                color: theme.colors.lightBg,
                zIndex: 1,
            },

            [theme.breakpoints.down('xs')]: {
                maxWidth: 100 + '%',
                height: 160,
                marginLeft: 0,
                marginRight: 0,
                flexBasis: 100 + '%',
            },

            ...ieFix({ marginLeft: 0, flexBasis: 46 + '%' }),
        },
        backgroundLayout: {
            backgroundColor: '#191717',
            opacity: 0.3,
            position: 'absolute',
            left: 0,
            top: 0,
            height: 100 + '%',
            width: 100 + '%',
            zIndex: 0,
            transition: 'background 0.5s ease-out, color 0.5s ease-out, box-shadow 0.5s ease-out',

            '&:hover, &:focus': {
                backgroundColor: 'rgba(25, 23, 23, 0.60)',
            },
        },
        backgroundLayoutSmall: {
            height: '275px !important',
        },
        itemSmall: {
            maxWidth: 30.8 + '%',
            flexBasis: 28 + '%',
            display: 'flex',
            flexShrink: 0,
            flexGrow: 1,
            flexDirection: 'column',
            boxSizing: 'border-box',
            paddingLeft: 15,
            paddingRight: 15,
            borderColor: '#cccccc',
            borderRadius: 0,
            borderStyle: 'solid',
            borderWidth: 1,
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 1.5 + 'rem',
            fontFamily: 'Arial, sans-serif',
            position: 'relative',

            [theme.breakpoints.down('xs')]: {
                maxWidth: 100 + '%',
                flexBasis: 100 + '%',
                marginLeft: 0,
                marginRight: 0,
            },

            ...ieFix({ marginLeft: 0, maxWidth: 31.7 + '%' }),
        },
        tagsPanel: {
            ...row(),
        },
        tag: {
            paddingLeft: 9,
            paddingRight: 9,
            marginLeft: 15,
            marginRight: 0,
            marginBottom: 15,
            fontWeight: 600,
            fontSize: 0.75 + 'rem',
            fontFamily: 'Arial, sans-serif',
            textTransform: 'uppercase',
            borderColor: '#cccccc',
            borderRadius: 0,
            borderStyle: 'solid',
            borderWidth: 1,

            [theme.breakpoints.down('xs')]: {
                marginBottom: 10,
            },
        },
        author: {
            ...row(),
            paddingLeft: 6,
            paddingRight: 9,
            marginLeft: 15,
            marginRight: 15,
            marginBottom: 15,
            borderColor: '#cccccc',
            borderRadius: 0,
            borderStyle: 'solid',
            borderWidth: 1,

            [theme.breakpoints.down('xs')]: {
                marginBottom: 10,
            },
        },
        logo: {
            display: 'inline-block',
            width: 0.75 + 'rem',
            height: 0.75 + 'rem',
            fill: theme.colors.accentLight,
            margin: 'auto 5px auto 0',
        },
        name: {
            marginBottom: 0,
            fontSize: 0.85 + 'rem',
            fontFamily: 'Arial, sans-serif',
            textDecoration: 'unset',
            color: theme.colors.contrastBg,

            ...ieFix({ textDecoration: 'none' }),
        },
        titleLink: {
            textDecoration: 'unset',

            ...ieFix({ textDecoration: 'none' }),
        },
        title: {
            fontSize: 1.3 + 'rem',
            fontWeight: 700,
            marginBottom: 0.6 + 'rem',
            color: theme.colors.contrastBg,
            overflow: 'hidden',
            display: '-webkit-box',
            '-webkit-line-clamp': 2,
            '-webkit-box-orient': 'vertical',

            [theme.breakpoints.down('xs')]: {
                fontSize: 1 + 'rem',
                marginBottom: 0,
                marginLeft: 0,
                marginRight: 0,
            },
        },
        titleSmall: {
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            fontSize: 1.3 + 'rem',
            fontWeight: 700,
            marginBottom: 0.6 + 'rem',
            color: theme.colors.contrastBg,
        },
        date: {
            fontSize: 0.75 + 'rem',
            fontWeight: 600,
            marginBottom: 0.45 + 'rem',
            color: theme.colors.contrastBg,
        },
        image: {
            height: 275,
            marginLeft: -15,
            marginRight: -15,
        },
        info: {
            ...column(),
            flexBasis: 'unset',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            backgroundColor: theme.colors.lightBg,
            marginTop: 15,
            marginLeft: -15,
            marginRight: -15,
        },
    });

interface IArticleCardProps {
    article: Pick<Article, 'id' | 'pictureBigId' | 'tags' | 'account' | 'title' | 'date' | 'slug' | 'pictureSmallId'>;
    showPicture?: boolean;
}

const BigArticleCard: React.StatelessComponent<IArticleCardProps & WithStyles<typeof styles>> = ({
    classes,
    article,
}) => {
    return (
        <LinksConsumer>
            {links => (
                <div
                    className={classes.itemBig}
                    style={{
                        background: `url("${getPictureUrl(
                            article.pictureBigId,
                            '570x305',
                            null,
                        )}") center center / cover no-repeat`,
                    }}
                >
                    <Link className={classes.backgroundLayout} to={links.articleDetail.url(article.slug)} />
                    <div className={classes.tagsPanel}>
                        {isFilledArray(article.tags) &&
                            article.tags.slice(0, 3).map((tag, index) => (
                                <div className={classes.tag} key={index}>
                                    {tag}
                                </div>
                            ))}
                        <div className={classes.author}>
                            <LecturerIcon className={classes.logo} />
                            <links.teacherDetail.Link
                                target="_blank"
                                rel="noopener noreferrer"
                                key={article.account.id}
                                className={classes.name}
                                to={links.teacherDetail.url(article.account.id)}
                            >
                                {article.account.firstName} {article.account.lastName}
                            </links.teacherDetail.Link>
                        </div>
                    </div>
                    <Link key={article.id} className={classes.titleLink} to={links.articleDetail.url(article.slug)}>
                        <p className={classes.title}>{article.title}</p>
                    </Link>
                    <p className={classes.date}>{formatToLocalDayMonthYear(article.date)}</p>
                </div>
            )}
        </LinksConsumer>
    );
};

const SmallArticleCard: React.FC<IArticleCardProps & WithStyles<typeof styles>> = ({
    classes,
    article,
    showPicture = true,
}) => {
    return (
        <LinksConsumer>
            {links => (
                <div className={classes.itemSmall}>
                    {showPicture && (
                        <>
                            <div
                                className={classes.image}
                                style={{
                                    background: `url("${getPictureUrl(
                                        article.pictureSmallId || article.pictureBigId,
                                        '370x275',
                                        null,
                                    )}") center center / cover no-repeat`,
                                }}
                            />
                            <Link
                                className={c(classes.backgroundLayout, classes.backgroundLayoutSmall)}
                                to={links.articleDetail.url(article.slug)}
                            />
                        </>
                    )}
                    <div className={classes.info}>
                        <div className={classes.tagsPanel}>
                            {isFilledArray(article.tags) &&
                                article.tags.slice(0, 3).map((tag, index) => (
                                    <div className={classes.tag} key={index}>
                                        {tag}
                                    </div>
                                ))}
                            <div className={classes.author}>
                                <LecturerIcon className={classes.logo} />
                                <links.teacherDetail.Link
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={article.account.id}
                                    className={classes.name}
                                    to={links.teacherDetail.url(article.account.id)}
                                >
                                    {article.account.firstName} {article.account.lastName}
                                </links.teacherDetail.Link>
                            </div>
                        </div>
                        <Link key={article.id} className={classes.titleLink} to={links.articleDetail.url(article.slug)}>
                            <p className={classes.title}>{article.title}</p>
                        </Link>
                        <p className={classes.date}>{formatToLocalDayMonthYear(article.date)}</p>
                    </div>
                </div>
            )}
        </LinksConsumer>
    );
};

export const StyledBigArticleCard = injectSheet(styles)(BigArticleCard);
export const StyledSmallArticleCard = injectSheet(styles)(SmallArticleCard);
