import c from 'classnames';
import SortArrows, { ISortDirection } from 'core/components/common/SortArrows';
import { useDeviceInfo } from 'core/components/DeviceInfoContext';
import { useLinks } from 'core/components/linksContext';
import injectSheet, { createStyles } from 'core/style/injectSheet';
import { ITheme, WithStyles } from 'core/style/interfaces';
import { formatNumberToDecimal } from 'core/utils';
import React from 'react';
import { Link } from 'react-router-dom';
import { IAscOrDesc } from 'site/components/vdo/types';
import { GetHighLowDataQuery } from 'site/graphql';
import InfoIcon from '../../svg/info.svg';
import { IHighLowSort } from './const';
import Graph from './Graph';

type ISecurityProps = {
    number: number;
    security: GetHighLowDataQuery['highLowData'][0];
    onOpenInfoModal: (security: GetHighLowDataQuery['highLowData'][0]) => void;
    orderFunc: (sort: IHighLowSort, newDirection: ISortDirection | undefined) => void;
    sort: IHighLowSort;
    direction: ISortDirection;
} & WithStyles<typeof securityStyles>;

const Security: React.FC<ISecurityProps> = ({
    classes,
    number,
    security,
    onOpenInfoModal,
    orderFunc,
    sort,
    direction,
}) => {
    const { name, d1, w1, m1, y1, yTD, secId, index, eTF } = security;
    const { isMobile } = useDeviceInfo();
    const links = useLinks();
    const renderPercent = (value: number) => (
        <div
            className={c(classes.percent, {
                [classes.percentRed]: value < 0,
                [classes.percentGreen]: value > 0,
            })}
        >
            {formatNumberToDecimal(value)}%
        </div>
    );

    const ordering = (orderName: IHighLowSort, label?: string, only?: IAscOrDesc) => {
        return (
            <SortArrows
                className={classes.sortBnt}
                label={!only ? label : undefined}
                descLabel={only === 'desc' ? label : undefined}
                ascLabel={only === 'asc' ? label : undefined}
                only={only}
                currentOrderName={sort}
                orderName={orderName}
                orderFunc={orderFunc}
                direction={direction}
            />
        );
    };

    const renderName = () => {
        switch (true) {
            case index === true:
                return (
                    <>
                        {number}. {name}
                        <br />
                        <span className={classes.etfName}>{secId}</span>
                    </>
                );
            case eTF === true:
                return (
                    <>
                        {number}. {secId} <br />
                        <span className={classes.etfName}>{name}</span>
                    </>
                );
            default:
                return (
                    <>
                        {number}. <Link to={links.dividendDetail.url(secId)}>{name}</Link> <br />
                        <span className={classes.etfName}>{secId}</span>
                    </>
                );
        }
    };

    const nameTitle = () => {
        switch (true) {
            case index === true:
                return 'Наименование / Тикер';
            case eTF === true:
                return 'Тикер / Наименование';
            default:
                return 'Компания / Тикер';
        }
    };

    // <name 250px> <graphContainer flex 1> <percent 80px> <percent 80px> <percent 80px> <percent 80px> <percent 80px> <root padding 40px>
    return (
        <>
            <div className={classes.root}>
                {!isMobile && number === 1 && (
                    <div className={classes.leftHeader}>
                        <div>{ordering('name', nameTitle())}</div>
                        <div className={classes.leftHeaderGraph}>
                            <div>{ordering('position', 'Low', 'asc')}</div>
                            <div />
                            <div>{ordering('position', 'High', 'desc')}</div>
                        </div>
                    </div>
                )}
                {!isMobile && number === 1 && (
                    <div className={classes.header}>
                        <div>{ordering('day', '1D')}</div>
                        <div>{ordering('week', '1W')}</div>
                        <div>{ordering('month', '1M')}</div>
                        <div>{ordering('ytd', 'YTD')}</div>
                        <div>{ordering('year', '1Y')}</div>
                    </div>
                )}
                {isMobile && (
                    <div className={classes.header}>
                        <div>1D</div>
                        <div>1W</div>
                        <div>1M</div>
                        <div>YTD</div>
                        <div>1Y</div>
                    </div>
                )}
                <div className={classes.name}>{renderName()}</div>
                <Graph security={security} />
                <div className={classes.percents}>
                    {renderPercent(d1)}
                    {renderPercent(w1)}
                    {renderPercent(m1)}
                    {renderPercent(yTD)}
                    {renderPercent(y1)}
                </div>
                <InfoIcon className={classes.infoIcon} onClick={() => onOpenInfoModal(security)} />
            </div>
        </>
    );
};

export const securityStyles = (theme: ITheme) =>
    createStyles({
        root: {
            display: 'flex',
            alignItems: 'center',
            paddingRight: 40,
            position: 'relative',

            [theme.breakpoints.down('xs')]: {
                flexDirection: 'column',
                paddingRight: 0,
                marginBottom: 1 + 'rem',

                '&:nth-child(odd):after': {
                    background: '#F3F3F3',
                    content: '""',
                    position: 'absolute',
                    top: -5,
                    bottom: -5,
                    left: 'calc((-100vw + 320px) / 2)',
                    right: 'calc((-100vw + 320px) / 2)',
                    zIndex: -1,
                },
            },

            [theme.breakpoints.down(321)]: {
                '&:nth-child(odd):after': {
                    left: 'calc((-100vw + 290px) / 2)',
                    right: 'calc((-100vw + 290px) / 2)',
                },
            },
        },
        name: {
            width: 250,
            fontSize: 1 + 'rem',
            lineHeight: 1,
            color: 'black',

            [theme.breakpoints.down('xs')]: {
                width: 100 + '%',
                fontWeight: 'bold',
                color: 'inherit',
                paddingRight: 30,
                boxSizing: 'border-box',
            },
        },
        percents: {
            display: 'flex',

            [theme.breakpoints.down('xs')]: {
                width: 100 + '%',
                justifyContent: 'space-between',
                order: 2,
                marginBottom: 5 + 'px',
                padding: 0,
            },
        },
        percent: {
            display: 'flex',
            height: 54,
            padding: '0.5rem 0',
            width: 80,
            fontSize: 0.85 + 'rem',
            color: 'black',
            alignItems: 'center',
            justifyContent: 'center',

            '&:nth-child(odd)': {
                backgroundColor: '#F3F3F3',
            },

            [theme.breakpoints.down('xs')]: {
                height: 'auto',
                width: 20 + '%',
                padding: 0,
                fontSize: 0.75 + 'rem',
                lineHeight: 'normal',

                '&:nth-child(odd)': {
                    backgroundColor: 'inherit',
                },
                '&:last-of-type': {
                    marginLeft: 40,
                },
            },
        },
        percentRed: {
            color: theme.colors.accent,
        },
        percentGreen: {
            color: theme.colors.green,
        },
        infoIcon: {
            position: 'absolute',
            height: 22,
            width: 22,
            right: 'calc(20px - 11px)',
            top: 'calc(50% - 11px)',
            cursor: 'pointer',
            fill: theme.colors.accent,

            '&:hover': {
                opacity: 0.6,
            },

            [theme.breakpoints.down('xs')]: {
                top: 0,
                right: 0,
                fill: 'black',
            },
        },
        header: {
            fontSize: 1 + 'rem',
            fontWeight: 'bold',
            display: 'flex',
            textAlign: 'center',
            paddingRight: 40,
            justifyContent: 'flex-end',
            position: 'absolute',
            right: 0,
            bottom: 'calc(54px + 1.5rem)',

            '& > div': {
                width: 80,
                display: 'flex',
                justifyContent: 'center',
            },

            [theme.breakpoints.down('xs')]: {
                position: 'static',
                marginTop: 1 + 'rem',
                paddingRight: 0,
                justifyContent: 'space-between',
                width: 100 + '%',
                order: 1,
                fontWeight: 'normal',
                fontSize: 0.85 + 'rem',
                lineHeight: 'normal',
                marginBottom: 5 + 'px',

                '& > div': {
                    width: 20 + '%',
                },
                '& > div:last-child': {
                    marginLeft: 40,
                },
            },
        },
        leftHeader: {
            fontSize: 1 + 'rem',
            fontWeight: 'bold',
            display: 'flex',
            textAlign: 'center',
            position: 'absolute',
            left: 0,
            right: 440,
            bottom: 'calc(54px + 1.5rem)',
            '& > div': {
                display: 'flex',
            },
            '& > :first-child': {
                width: 250,
                textAlign: 'left',
            },
            '& > :last-of-type': {
                flexGrow: 1,
            },

            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        leftHeaderGraph: {
            fontSize: 1 + 'rem',
            fontWeight: 'bold',
            display: 'flex',
            textAlign: 'center',

            '& > div': {
                display: 'flex',
                justifyContent: 'center',
            },
            '& > div:first-child': {
                width: 100,
            },
            '& > div:nth-child(2)': {
                left: 0,
                right: 0,
                flexGrow: 1,
            },
            '& > div:last-child': {
                width: 100,
            },

            [theme.breakpoints.down('xs')]: {
                display: 'none',
            },
        },
        headerOrder: {
            fontSize: 14,
            marginLeft: 10,
            alignSelf: 'center',
            display: 'flex',
            cursor: 'pointer',
            color: '#666666',
        },
        orderEnabled: {
            color: '#000000',
            fontWeight: 'bold',
        },
        orderDisabled: {
            color: '#bbbbbb',
            fontWeight: 'bold',
        },
        sortBnt: {
            fontSize: 1 + 'rem',
            fontWeight: 'bold',
        },
        etfName: {
            fontSize: 0.8 + 'rem',
            color: '#9D9D9D',
        },
    });

export default injectSheet(securityStyles)(Security);
